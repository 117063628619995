import { GET_USER_CARDS,GET_CARD_OFFER,GET_PAYMENT_CURRENCIES,GET_TRANSACTION_STATUS } from "../actions";

const initialState = {
  user_cards: null,
  card_offers:null,
  PaymentCurrencies:null,
  tx_status: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_CARDS:
      return {
        ...state,
        user_cards: action.payload,
      };

      case GET_CARD_OFFER:
        return {
          ...state,
          card_offers: action.payload,
        };
        case GET_PAYMENT_CURRENCIES:
        return {
          ...state,
          PaymentCurrencies: action.payload,
        };
        case GET_TRANSACTION_STATUS:
          return {
            ...state,
            tx_status: action.payload,
          };
    default:
      return state;
  }
}
