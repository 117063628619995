import dashboard from './dashboard';
import bccdashboard from './bccdashboard'
import Roledashboard from './Roledashboard'

import pages from './pages';
import utilities from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard],
    bccitems: [bccdashboard],
    Roleitems:[Roledashboard]

};

export default menuItems;
