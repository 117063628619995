const initialState = {
    addresses: [],
    loading: false,
    error: null,
};

export default function withdrawalAddressReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_ADDRESSES_START':
            return {
                ...state,
                loading: true,
            };
        case 'FETCH_ADDRESSES_SUCCESS':
            return {
                ...state,
                addresses: action.payload,
                loading: false,
            };
        case 'FETCH_ADDRESSES_FAIL':
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case 'ADD_ADDRESS_SUCCESS':
            return {
                ...state,
                addresses: [...state.addresses, action.payload],
            };
        case 'DELETE_ADDRESS_SUCCESS':
            return {
                ...state,
                addresses: state.addresses.filter((address) => address.id !== action.payload),
            };
        case 'TOGGLE_STATUS_SUCCESS':
            return {
                ...state,
                addresses: state.addresses.map((address) =>
                    address.id === action.payload.id ? { ...address, status: action.payload.status } : address
                ),
            };
        default:
            return state;
    }
}
