import {lazy} from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import("views/dashboard/Default")));
const MyWallet = Loadable(lazy(() => import("views/pages/MyWallet")));
const WhiteList = Loadable(lazy(() => import("views/pages/WhiteList/WhiteList")));
const History = Loadable(lazy(() => import("views/pages/UserHistory/History")));
const BuyBCCoin = Loadable(lazy(() => import("views/pages/StakeCoin/BuyBCCoin")));
const ReferralsHistory = Loadable(lazy(() => import("views/pages/Referrals/History")));
const ReferralsShare = Loadable(lazy(() => import("views/pages/Referrals/Share")));
const ReferralsTree = Loadable(lazy(() => import("views/pages/Referrals/TreeChart")));
const AboutUs = Loadable(lazy(() => import("views/pages/About/Aboutus")));
const DashboardBank = Loadable(lazy(() => import("views/Bank/Default")));
const MyCard = Loadable(lazy(() => import("views/pages/mycard/MyCard")));
const Deposits = Loadable(lazy(() => import("views/pages/Deposite/Deposits")));
const BankTransactions = Loadable(lazy(() => import("views/pages/BankTransaction/Transaction")));
const KYC = Loadable(lazy(() => import("views/pages/KYCVerify/KYC")));
const KYCRegistration = Loadable(lazy(() => import("views/pages/BankRegForm/Regform")));

const Profile = Loadable(lazy(() => import("views/pages/Profile/Profile")));
const ChangePass = Loadable(lazy(() => import("views/pages/ChangePassword/ChangePass")));
const CryptoPay = Loadable(lazy(() => import("views/pages/CryptoPay/Pay")));
const Claims = Loadable(lazy(() => import("views/pages/AllClaims/UserClaims")));

const Orders = Loadable(lazy(() => import("views/pages/Orders/Orders")));
const Perks = Loadable(lazy(() => import("views/pages/Perks/Perks")));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "/",
            element: <DashboardDefault />,
        },
        {
            path: "dashboard",
            children: [
                {
                    path: "",
                    element: <DashboardDefault />,
                },
            ],
        },
        {
            path: "utils",
            children: [
                {
                    path: "my-wallet",
                    element: <MyWallet />,
                },
            ],
        },
        {
            path: "utils",
            children: [
                {
                    path: "withdrawal-addresses",
                    element: <WhiteList />,
                },
            ],
        },

        {
            path: "utils",
            children: [
                {
                    path: "history",
                    element: <History />,
                },
            ],
        },
        {
            path: "utils",
            children: [
                {
                    path: "buy/coins",
                    element: <BuyBCCoin />,
                },
            ],
        },
        {
            path: "utils",
            children: [
                {
                    path: "about",
                    element: <AboutUs />,
                },
            ],
        },
        {
            path: "marketplace",
            children: [
                {
                    path: "perks",
                    element: <Perks />,
                },
                {
                    path: "orders",
                    element: <Orders />,
                },
            ],
        },
        {
            path: "utils",
            children: [
                {
                    path: "claim-history",
                    element: <Claims />,
                },
            ],
        },
        {
            path: "utils",
            children: [
                {
                    path: "referrals",
                    element: <ReferralsShare />,
                },
            ],
        },
        {
            path: "utils",
            children: [
                {
                    path: "referrals-tree",
                    element: <ReferralsTree />,
                },
            ],
        },

        // {
        //     path: 'referrals',
        //     children: [
        //         {
        //             path: 'history',
        //             element: <ReferralsHistory />
        //         },
        //         {
        //             path: 'share',
        //             element: <ReferralsShare />
        //         }
        //     ]
        // },
        {
            path: "bank",
            children: [
                {
                    path: "dashboard",
                    element: <DashboardBank />,
                },
                {
                    path: "mycard",
                    element: <MyCard />,
                },
                {
                    path: "deposits",
                    element: <Deposits />,
                },
                {
                    path: "transactions",
                    element: <BankTransactions />,
                },
                {
                    path: "kyc-registration",
                    element: <KYCRegistration />,
                },
                {
                    path: "kyc-verify",
                    element: <KYC />,
                },
            ],
        },
        {
            path: "profile",
            element: <Profile />,
        },
        {
            path: "change_password",
            element: <ChangePass />,
        },
        {
            path: "pay/:id",
            element: <CryptoPay />,
        },
    ],
};

export default MainRoutes;
