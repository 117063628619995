import { GET_KYC_STATUS,GET_RETRY_KYC } from "../actions";

const initialState = {
  kyc_status: null,
  retry_kyc:null,
  kyc_error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_KYC_STATUS:
      return {
        ...state,
        kyc_status: action.payload,
        kyc_error: null,
      };
      case GET_RETRY_KYC:
        return {
          ...state,
          retry_kyc: action.payload,
        };
  
    default:
      return state;
  }
}
