import {GET_ALL_USERS, GET_WALLETS, CHECKER_IMPERSONATE, GET_REFERRES_PERC} from "../actions";

const initialState = {
    AllUser: null,
    Wallets: null,
    checker_impersonate: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_USERS:
            return {
                ...state,
                AllUser: action.payload,
            };
        case GET_WALLETS:
            return {
                ...state,
                Wallets: action.payload,
            };
        case CHECKER_IMPERSONATE:
            return {
                ...state,
                checker_impersonate: action.payload,
            };
            case GET_REFERRES_PERC:
                return {
                    ...state,
                    reffers_percentage: action.payload,
                };
        default:
            return state;
    }
}
