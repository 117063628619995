import { GET_HISTROY_DATA, GET_ALL_TRANSACTION_DATA, GET_TRANSACTION_CSV } from "../actions";

const initialState = {
    History: null,
    AllTransaction: null,
    TransactionCsv: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_HISTROY_DATA:
            return {
                ...state,
                History: action.payload,
            };
        case GET_ALL_TRANSACTION_DATA:
            return {
                ...state,
                AllTransaction: action.payload,
            };
        case GET_TRANSACTION_CSV:
            return {
                ...state,
                TransactionCsv: action.payload,
            };
        default:
            return state;
    }
}