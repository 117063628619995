import {LOADER_STATUS, AUTHENTICATOR_STATUS, DATA_LOADER} from "../actions";

export const changeflag = (flag) => (dispatch) => {
    dispatch({
        type: LOADER_STATUS,
        payload: flag,
    });
};

export const dataLoader = (flag) => (dispatch) => {
    dispatch({
        type: DATA_LOADER,
        payload: flag,
    });
};

export const changeAuthentication = (flag) => (dispatch) => {
    dispatch({
        type: AUTHENTICATOR_STATUS,
        payload: flag,
    });
};
