const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: "/",
    defaultPath: "/login",
    fontFamily: `"Inter", sans-serif`,
    borderRadius: 25,
    PUBLIC_KEY_WEB: "BA8QDY-BP4q0-Q9b2-Ca7SL6B8mAWoAw-y6eGOvvD_-1MLzFSeSgSeKovTOVQZh7s2sxMenWPJhR4_juEEv63Wg",
    GOOGLE_CLIENT_ID: "57635143820-dd3mrrki9sa30i61j428kh5h8un00422.apps.googleusercontent.com",
};

export default config;
