// action - customization reducer
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";

//Auth
export const GET_LOGIN_DATA = "GET_LOGIN_DATA";
export const GET_FORGOT_DATA = "GET_FORGOT_DATA";
export const GET_REGISTER_MESSAGE = "GET_REGISTER_MESSAGE";
export const GET_TOKEN = "GET_TOKEN";
export const GET_ROLE_LISTNG = "GET_ROLE_LISTNG";

//PERK
export const GET_PERKS = "GET_PERKS";
export const UPDATE_PERK = "UPDATE_PERK";
export const CREATE_PERKS = "CREATE_PERKS";


//ORDERS
export const GET_ORDERS = "GET_ORDERS";


//Loader Status
export const LOADER_STATUS = "LOADER_STATUS";
export const AUTHENTICATOR_STATUS = "AUTHENTICATOR_STATUS";
export const DATA_LOADER = "DATA_LOADER";


//TRANSACTION HISTORY
export const GET_HISTROY_DATA = "GET_HISTROY_DATA";
export const GET_ALL_TRANSACTION_DATA = "GET_ALL_TRANSACTION_DATA";
export const GET_TRANSACTION_CSV = "GET_TRANSACTION_CSV";



// Referral
export const SET_REFERRAL_DATA = "SET_REFERRAL_DATA";
export const SET_REFERRAL_USERS = "SET_REFERRAL_USERS";
export const SET_REFERRAL_TRANSACTIONS = "SET_REFERRAL_TRANSACTIONS";
export const SET_SOCIAL_INFO = "SET_SOCIAL_INFO";
export const GET_USER_REFFERALS = "GET_USER_REFFERALS";
export const GET_REFERRES_PERC = "GET_REFERRES_PERC";
export const UPDATE_REFERRES_PERC = "UPDATE_REFERRES_PERC";

//USERS HISTORY
export const GET_ALL_USERS = "GET_ALL_USERS";

//SOCIAL SHARE HISTORY
export const SOCIAL_SHARE_HISTORY = "SOCIAL_SHARE_HISTORY";
export const GET_WALLETS = "GET_WALLETS";

//NOTIFICATIONS
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

//USER DASHBOARD DATA 
export const USER_DASHBOARD_DATA  = "USER_DASHBOARD_DATA";
export const ADMIN_DASHBOARD_DATA  = "ADMIN_DASHBOARD_DATA";

//GET_LANGUAGE
export const GET_LANGUAGE  = "GET_LANGUAGE";

//KYC
export const GET_KYC_STATUS  = "GET_KYC_STATUS";
export const GET_RETRY_KYC  = "GET_RETRY_KYC";
export const GET_KYC_ERROR  = "GET_KYC_ERROR";

//DEPOSITS
export const GET_USER_DEPOSITS  = "GET_USER_DEPOSITS";
export const GET_USER_TOPUPS  = "GET_USER_TOPUPS";

//CARDS
export const GET_USER_CARDS  = "GET_USER_CARDS";
export const GET_CARD_OFFER  = "GET_CARD_OFFER";
export const GET_PAYMENT_CURRENCIES  = "GET_PAYMENT_CURRENCIES";
export const GET_TRANSACTION_STATUS  = "GET_TRANSACTION_STATUS";

// Bank Transaction
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";

// Display card balance
export const GET_CARD_BALANCES = "GET_CARD_BALANCES";

// Claims
export const GET_ALL_CLAIMS = "GET_ALL_CLAIMS";

// Analytics
export const GET_ALL_ANALYTICS = "GET_ALL_ANALYTICS";

export const CHECKER_IMPERSONATE = "CHECKER_IMPERSONATE";
