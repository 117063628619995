import {borderColor, borderRadius, borderRight} from "@mui/system";

export default function componentStyleOverrides(theme) {
    const bgColor = "#1C1B1B";
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: "4px",
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: "24px",
                },
                title: {
                    fontSize: "1.125rem",
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: "24px",
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: "24px",
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: "#fff !important",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    "&.Mui-selected": {
                        color: theme.menuSelected,
                        backgroundImage: `linear-gradient(to right, #536229, #CAEE64 150%)`,
                        borderRight: "4px solid #CBF065",
                        borderRadius: "0",
                        "&:hover": {
                            backgroundColor: "#CAEE64",
                        },
                        "& .MuiListItemIcon-root": {
                            color: "#CBF065 !important",
                        },
                    },
                    "&:hover": {
                        // backgroundColor: 'rgba(83, 98, 41, 0.5)',
                        backgroundColor: "transparent",
                        color: "#CBF065 !important",
                        borderRadius: "0",
                        "& .MuiListItemIcon-root": {
                            color: "#CBF065 !important",
                        },
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: "36px",
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: "#fff !important",
                    "&:hover": {
                        color: "#CBF065 !important",
                    },
                },
                root: {
                    "&:hover": {
                        color: "#CBF065 !important",
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    "&::placeholder": {
                        color: theme.darkTextSecondary,
                        fontSize: "0.875rem",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: bgColor,
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.colors?.grey400,
                    },
                    "&:hover $notchedOutline": {
                        borderColor: theme.colors?.primaryLight,
                    },
                    "&.MuiInputBase-multiline": {
                        padding: 1,
                    },
                    "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#B9FC3E !important",
                        borderWidth: "1px",
                    },
                },
                input: {
                    fontWeight: 500,
                    color: "#F4F4F4",
                    background: bgColor,
                    padding: "15.5px 14px",
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    "&.MuiInputBase-inputSizeSmall": {
                        padding: "10px 14px",
                        "&.MuiInputBase-inputAdornedStart": {
                            paddingLeft: 0,
                        },
                    },
                },
                inputAdornedStart: {
                    paddingLeft: 4,
                },
                notchedOutline: {
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        color: theme.colors?.grey300,
                    },
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: "4px",
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryDark,
                    background: theme.colors?.primary200,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    "&.MuiChip-deletable .MuiChip-deleteIcon": {
                        color: "inherit",
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700,
                },
            },
        },
    };
}
