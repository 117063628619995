import PropTypes from "prop-types";
import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

// material-ui
import {useTheme} from "@mui/material/styles";
import {Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";

// project imports
import NavItem from "../NavItem";
import {getPerks} from "store/Perks/perk.actions";
// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {IconChevronDown, IconChevronUp} from "@tabler/icons";
import {useTranslation} from "react-i18next";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

import {getWallets} from "../../../../../store/Users/User.action";

const NavCollapse = ({menu, level}) => {
    const dispatch = useDispatch();

    const {t} = useTranslation();
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const {login_Session} = useSelector((state) => state.auth);
    const {Wallets} = useSelector((state) => state?.users);
    const {perks} = useSelector((state) => state?.perks);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    useEffect(() => {
        dispatch(getWallets());
        dispatch(getPerks());
    }, []);

    console.log("login_Session", login_Session);
    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case "collapse":
                return (perks?.data?.length == 0 || !login_Session?.isEmailVerify) && item.id == "marketplace" ? (
                    ""
                ) : (
                    <NavCollapse key={item.id} menu={item} level={level + 1} />
                );
            case "item":
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon color="#fff" strokeWidth={1.5} size="1.3rem" style={{marginTop: "auto", marginBottom: "auto"}} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6,
            }}
            fontSize={level > 0 ? "inherit" : "medium"}
            color="#fff"
        />
    );

    function SideTitleTranslate(id) {
        switch (id) {
            case "referral":
                return t("referrals");
            case "bank_module":
                return t("black_card");
            case "marketplace":
                return t("marketplace");

            default:
                return menu.title;
        }
    }

    return (
        <>
            {(login_Session?.invester_type === "starter" && (Wallets ? Wallets.totalPaidAmount < 1000 : true)) ||
            login_Session?.isEmailVerify == false ? (
                menu?.id === "bank_module" ? (
                    ""
                ) : (
                    <>
                        <ListItemButton
                            sx={{
                                borderRadius: `${0}px`,
                                mb: 0.5,
                                alignItems: "flex-start",
                                backgroundColor: level > 1 ? "transparent !important" : "inherit",
                                py: level > 1 ? 1 : 1.25,
                                pl: `${level * 24}px`,
                            }}
                            selected={selected === menu.id}
                            onClick={() => {
                                handleClick();
                            }}
                        >
                            <ListItemIcon sx={{my: "auto", minWidth: !menu.icon ? 18 : 36}}>{menuIcon}</ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant={selected === menu.id ? "h5" : "body1"} color="#fff" sx={{my: "auto"}}>
                                        {SideTitleTranslate(menu.id)}
                                    </Typography>
                                }
                                secondary={
                                    menu.caption && (
                                        <Typography
                                            variant="caption"
                                            sx={{...theme.typography.subMenuCaption}}
                                            display="block"
                                            gutterBottom
                                        >
                                            {menu.caption}
                                        </Typography>
                                    )
                                }
                            />
                            {open ? (
                                <IconChevronUp color="#fff" stroke={1.5} size="1rem" style={{marginTop: "auto", marginBottom: "auto"}} />
                            ) : (
                                <IconChevronDown color="#fff" stroke={1.5} size="1rem" style={{marginTop: "auto", marginBottom: "auto"}} />
                            )}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List
                                component="div"
                                disablePadding
                                sx={{
                                    position: "relative",
                                    "&:after": {
                                        content: "''",
                                        position: "absolute",
                                        left: "32px",
                                        top: 0,
                                        height: "100%",
                                        width: "1px",
                                        opacity: 1,
                                        background: theme.palette.primary.light,
                                    },
                                }}
                            >
                                {menus}
                            </List>
                        </Collapse>
                    </>
                )
            ) : (
                <>
                    <ListItemButton
                        sx={{
                            borderRadius: `${customization.borderRadius}px`,
                            mb: 0.5,
                            alignItems: "flex-start",
                            backgroundColor: level > 1 ? "transparent !important" : "inherit",
                            py: level > 1 ? 1 : 1.25,
                            pl: `${level * 24}px`,
                        }}
                        selected={selected === menu.id}
                        onClick={() => {
                            handleClick();
                        }}
                    >
                        <ListItemIcon sx={{my: "auto", minWidth: !menu.icon ? 18 : 36}}>{menuIcon}</ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant={selected === menu.id ? "h5" : "body1"} color="#fff" sx={{my: "auto"}}>
                                    {SideTitleTranslate(menu.id)}
                                </Typography>
                            }
                            secondary={
                                menu.caption && (
                                    <Typography variant="caption" sx={{...theme.typography.subMenuCaption}} display="block" gutterBottom>
                                        {menu.caption}
                                    </Typography>
                                )
                            }
                        />
                        {open ? (
                            <IconChevronUp color="#fff" stroke={1.5} size="1rem" style={{marginTop: "auto", marginBottom: "auto"}} />
                        ) : (
                            <IconChevronDown color="#fff" stroke={1.5} size="1rem" style={{marginTop: "auto", marginBottom: "auto"}} />
                        )}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List
                            component="div"
                            disablePadding
                            // sx={{
                            //     position: "relative",
                            //     "&:after": {
                            //         content: "''",
                            //         position: "absolute",
                            //         left: "32px",
                            //         top: 0,
                            //         height: "100%",
                            //         width: "1px",
                            //         opacity: 1,
                            //         background: theme.palette.primary.light,
                            //     },
                            // }}
                        >
                            {menus}
                        </List>
                    </Collapse>
                </>
            )}
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number,
};

export default NavCollapse;
