import {GET_ALL_TRANSACTIONS} from "../actions";

const initialState = {
    bank_transactions: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_TRANSACTIONS:
            return {
                ...state,
                bank_transactions: action.payload,
            };

        default:
            return state;
    }
}
