import { 
  SET_REFERRAL_DATA, 
  SET_REFERRAL_USERS, 
  SET_REFERRAL_TRANSACTIONS,
  SET_SOCIAL_INFO,
  GET_USER_REFFERALS
} from '../actions';

const initialState = {
  referralData: null,
  referrals: [],
  referralTransactions: [],

  // social Info
  socialInfo: [],
};

export default function (state = initialState, action) {
  switch (action.type) {

    case SET_REFERRAL_TRANSACTIONS:
      return {
        ...state,
        referralTransactions: action.payload
      };
      case GET_USER_REFFERALS:
        return {
          ...state,
          userReferrals: action.payload
        }; 
    case SET_REFERRAL_USERS:
      return {
        ...state,
        referrals: action.payload
      };

    case SET_REFERRAL_DATA:
      return {
        ...state,
        referralData: action.payload
      };

    case SET_SOCIAL_INFO:
      return {
        ...state,
        socialInfo: action.payload
      };

    default:
      return state;
  }
}
