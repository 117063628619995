import { SOCIAL_SHARE_HISTORY } from "../actions";

const initialState = {
    sharingList: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SOCIAL_SHARE_HISTORY:
            return {
                ...state,
                sharingList: action.payload,
            };
        default:
            return state;
    }
}