import { GET_LOGIN_DATA, GET_FORGOT_DATA,GET_ROLE_LISTNG } from "../actions";

const initialState = {
  login_Session: null,
  forgot_email: null,
  Role_Listing:null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FORGOT_DATA:
      return {
        ...state,
        forgot_email: action.payload,
      };
    case GET_LOGIN_DATA:
      return {
        ...state,
        login_Session: action.payload,
      };
      case GET_ROLE_LISTNG:
        return {
          ...state,
          Role_Listing: action.payload,
        };

    default:
      return state;
  }
}
