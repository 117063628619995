import { GET_USER_DEPOSITS , GET_USER_TOPUPS} from "../actions";

const initialState = {
  user_deposits: null,
  user_topup  : null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_DEPOSITS:
      return {
        ...state,
        user_deposits: action.payload,
      };
      case GET_USER_TOPUPS:
        return {
          ...state,
          user_topup: action.payload,
        };
    default:
      return state;
  }
}
