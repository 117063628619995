// assets
import { IconDashboard } from "@tabler/icons";
import { MdWallet, MdHistory, MdOutlineLogout } from "react-icons/md";
import { FaRegCreditCard, FaUsersRays,FaUsers ,FaLink  } from "react-icons/fa6";
import { BiTransferAlt } from "react-icons/bi";
import { IoShareSocial } from "react-icons/io5";
import { FaFileInvoice,FaUsersCog  } from "react-icons/fa";
import { SiSubstack } from "react-icons/si";


// constant
const icons = { IconDashboard, MdWallet, MdHistory, FaRegCreditCard, MdOutlineLogout, FaUsersRays,FaUsers,BiTransferAlt,IoShareSocial, FaFileInvoice, SiSubstack,FaLink,FaUsersCog    };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: "dashboard",
    title: "Dashboard",
    type: "group",
    children: [
        {
            id: "dashboard",
            title: "Dashboard",
            type: "item",
            url: "/role-dashboard",
            icon: icons.IconDashboard,
            breadcrumbs: false,
        },
        {
            id: "genelink",
            title: "Generate Link",
            type: "item",
            url: "/generate/link",
            icon: icons.FaLink,
            breadcrumbs: false,
        },
        {
            id: "logout",
            title: "Logout",
            type: "item",
            url: "",
            icon: icons.MdOutlineLogout,
            breadcrumbs: false,
        },
    ],
};

export default dashboard;
