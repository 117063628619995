// assets
import {IconDashboard} from "@tabler/icons";
import {
    MdWallet,
    MdHistory,
    MdOutlineLogout,
    MdShare,
    MdLocationOn,
    MdExitToApp,
    MdAccountBalanceWallet,
    MdSend,
    MdOutlineReceiptLong,
} from "react-icons/md";
import {FaRegCreditCard, FaUsersRays, FaUserShield} from "react-icons/fa6";
import {FaShareAlt} from "react-icons/fa";
import {IoIosInformationCircleOutline} from "react-icons/io";
import {TbBuildingBank} from "react-icons/tb";
import {BsFillCreditCard2FrontFill} from "react-icons/bs";
import {RiExchangeDollarFill} from "react-icons/ri";
import {FaMoneyCheckDollar} from "react-icons/fa6";
import {HiMiniIdentification} from "react-icons/hi2";
import {BiDollar} from "react-icons/bi";
import {FiDollarSign} from "react-icons/fi";
import {FaShoppingBag} from "react-icons/fa";
import {GoGift} from "react-icons/go";
import {RiShoppingCartLine} from "react-icons/ri";

// constant
const icons = {
    IconDashboard,
    MdWallet,
    MdHistory,
    FaRegCreditCard,
    MdOutlineLogout,
    FaUsersRays,
    MdShare,
    IoIosInformationCircleOutline,
    FaUserShield,
    TbBuildingBank,
    BsFillCreditCard2FrontFill,
    RiExchangeDollarFill,
    FaMoneyCheckDollar,
    HiMiniIdentification,
    FaShoppingBag,
    FiDollarSign,
    GoGift,
    RiShoppingCartLine,
    MdLocationOn,
    MdExitToApp,
    MdAccountBalanceWallet,
    MdOutlineReceiptLong,
    MdSend,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: "dashboard",
    title: "Dashboard",
    type: "group",
    children: [
        {
            id: "bc_coin",
            title: `BCCoin`,
            type: "collapse",
            url: "",
            icon: icons.FiDollarSign,
            breadcrumbs: false,
            children: [
                {
                    id: "dashboard",
                    title: "Dashboard",
                    type: "item",
                    url: "dashboard",
                    icon: icons.IconDashboard,
                    breadcrumbs: false,
                },
                {
                    id: "myWallet",
                    title: "My Wallet",
                    type: "item",
                    url: "utils/my-wallet",
                    icon: icons.MdWallet,
                    breadcrumbs: false,
                },
                {
                    id: "withdrawal_addresses",
                    title: "Withdrawal Addresses",
                    type: "item",
                    url: "utils/withdrawal-addresses",
                    icon: icons.MdSend,
                    breadcrumbs: false,
                },

                {
                    id: "claim_history",
                    title: "Claim History",
                    type: "item",
                    url: "utils/claim-history",
                    icon: icons.MdHistory,
                    breadcrumbs: false,
                },

                {
                    id: "referral",
                    title: "Referrals",
                    type: "item",
                    url: "/utils/referrals",
                    icon: icons.FaUsersRays,
                    breadcrumbs: false,
                },
                {
                    id: "referral-tree",
                    title: "Referrals Tree",
                    type: "item",
                    url: "/utils/referrals-tree",
                    icon: icons.FaUsersRays,
                    breadcrumbs: false,
                },

                {
                    id: "history",
                    title: "History",
                    type: "item",
                    url: "utils/history",
                    icon: icons.MdHistory,
                    breadcrumbs: false,
                },

                {
                    id: "marketplace",
                    title: "Marketplace",
                    type: "collapse",
                    url: "",
                    icon: icons.FaShoppingBag,
                    children: [
                        {
                            id: "perks",
                            title: "Perks",
                            type: "item",
                            url: "/marketplace/perks",
                            icon: icons.GoGift,
                            breadcrumbs: false,
                        },
                        {
                            id: "orders",
                            title: "Orders",
                            type: "item",
                            url: "/marketplace/orders",
                            icon: icons.RiShoppingCartLine,
                            breadcrumbs: false,
                        },
                    ],
                    breadcrumbs: false,
                },
                {
                    id: "buybbc",
                    title: "Stake More $BCCoin",
                    type: "item",
                    url: "/utils/buy/coins",
                    icon: icons.FaRegCreditCard,
                    breadcrumbs: false,
                },
                {
                    id: "about",
                    title: "About Us",
                    type: "item",
                    url: "/utils/about",
                    icon: icons.IoIosInformationCircleOutline,
                    breadcrumbs: false,
                },
            ],
        },
        {
            id: "bank_module",
            title: "BlackCard",
            type: "collapse",
            url: "",
            icon: icons.TbBuildingBank,
            breadcrumbs: false,
            children: [
                {
                    id: "bank_dashboard",
                    title: "Dashboard",
                    type: "item",
                    url: "bank/dashboard",
                    icon: icons.IconDashboard,
                    // target: true,
                    breadcrumbs: false,
                },
                {
                    id: "bank_mycard",
                    title: "My Card",
                    type: "item",
                    url: "bank/mycard",
                    icon: icons.BsFillCreditCard2FrontFill,
                    // target: true,
                    breadcrumbs: false,
                },
                // {
                //     id: 'bank_deposits',
                //     title: 'Deposits',
                //     type: 'item',
                //     url: 'bank/deposits',
                //     icon: icons.RiExchangeDollarFill,
                //     // target: true,
                //     breadcrumbs: false
                // },
                {
                    id: "bank_transactions",
                    title: "Transactions",
                    type: "item",
                    url: "bank/transactions",
                    icon: icons.FaMoneyCheckDollar,
                    // target: true,
                    breadcrumbs: false,
                },
                {
                    id: "bank_kyc_reg",
                    title: "KYC Registration",
                    type: "item",
                    url: "bank/kyc-registration",
                    icon: icons.HiMiniIdentification,
                    // target: true,
                    breadcrumbs: false,
                },
                {
                    id: "bank_kyc_verify",
                    title: "KYC Verify",
                    type: "item",
                    url: "bank/kyc-verify",
                    icon: icons.FaUserShield,
                    // target: true,
                    breadcrumbs: false,
                },
            ],
        },

        // {
        //     id: "gokyc",
        //     title: "Go to Kyc",
        //     type: "item",
        //     url: "",
        //     icon: icons.FaUserShield,
        //     breadcrumbs: false,
        // },

        // {
        //     id: "referral",
        //     title: "Referrals",
        //     type: "collapse",
        //     url: "",
        //     icon: icons.FaUsersRays,
        //     breadcrumbs: false,
        //     children: [
        //         {
        //             id: 'referrals/history',
        //             title: 'History',
        //             type: 'item',
        //             url: '/referrals/history',
        //             icon: icons.MdHistory,
        //             // target: true,
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'referrals/share',
        //             title: 'Share',
        //             type: 'item',
        //             url: '/referrals/share',
        //             icon: icons.MdShare,
        //             // target: true,
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: "logout",
            title: "Logout",
            type: "item",
            url: "",
            icon: icons.MdOutlineLogout,
            breadcrumbs: false,
        },
    ],
};

export default dashboard;
