import {CHECKER_IMPERSONATE, GET_ALL_USERS, GET_WALLETS, GET_REFERRES_PERC, UPDATE_REFERRES_PERC} from "../actions";
import toast from "react-hot-toast";
import Axios from "../../utils/Axios";
import {changeflag} from "../loader/loader.action";

export const GetAllUsers = (query) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.get(`users/allusers?${query}`)
        .then((res) => {
            dispatch({
                type: GET_ALL_USERS,
                payload: res.data,
            });
            dispatch(changeflag(false));
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const getWallets = () => (dispatch) => {
    // dispatch(changeflag(true));
    Axios.get("users/getWallets")
        .then((res) => {
            dispatch({
                type: GET_WALLETS,
                payload: res.data,
            });
            // dispatch(changeflag(false));
        })
        .catch((error) => {
            // dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const ResetUserPassword = (Data, onClose) => (dispatch) => {
    Axios.post("users/resetpasswordadmin", Data)
        .then((res) => {
            let data = res.data;
            if (data.success == true) {
                toast.success(data.message);
                onClose(false);
                dispatch(GetAllUsers());
            }
        })
        .catch((error) => {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const UpdateReferral = (Data, id, onClose) => (dispatch) => {
    Axios.put(`users/refferalcode/${id}`, Data)
        .then((res) => {
            let data = res.data;
            if (data.success == true) {
                toast.success(data.message);
                onClose(false);
                dispatch(GetAllUsers());
            }
        })
        .catch((error) => {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const getImpersonate = () => (dispatch, id) => {
    // dispatch(changeflag(true));
    Axios.get(`users/impersonate/${id}`)
        .then((res) => {
            dispatch({
                type: GET_WALLETS,
                payload: res.data,
            });
            // dispatch(changeflag(false));
        })
        .catch((error) => {
            // dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const checkerImpersonate = () => (dispatch, id) => {
    dispatch(changeflag(true));
    Axios.get(`users/impersonatChecker`)
        .then((res) => {
            dispatch({
                type: CHECKER_IMPERSONATE,
                payload: res.data.success,
            });
            dispatch(changeflag(false));
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.response) {
                // toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const migrateUserApi = (Data, setClose) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.post("users/migrate", Data)
        .then((res) => {
            if (res.data.success) {
                setClose(false);
                if (res?.data?.otp_verified === false) {
                    setClose(true);
                } else {
                    setClose(false);
                }
                dispatch(changeflag(false));
            }
        })
        .catch((error) => {
            dispatch(changeflag(false));
            setClose(false);
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const listReffersPercentage = () => (dispatch) => {
    Axios.get("users/listReffersPercentage")
        .then((res) => {
            dispatch({
                type: GET_REFERRES_PERC,
                payload: res.data.data,
            });
        })
        .catch((error) => {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const updateReffersPercentage = (Data, setopenModal) => (dispatch) => {
    console.log(Data);
    Axios.put("users/updateReffersPercentage", Data)
        .then((res) => {
            if (res.data.success) {
                if (res?.data?.otp_verified === false) {
                    setopenModal(true);
                } else {
                    setopenModal(false);
                }
                let msg = res.data.message;
                dispatch(listReffersPercentage());
                toast.success(msg ? msg : "Referres Percentage Updated Successfully.");
            }
        })
        .catch((error) => {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};
