import PropTypes from "prop-types";
import {forwardRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
// material-ui
import {useTheme} from "@mui/material/styles";
import {Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery} from "@mui/material";
// project imports
import {MENU_OPEN, SET_MENU} from "store/actions";
// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {logout} from "../../../../../store/auth/auth.action";
import {useTranslation} from "react-i18next";
// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({item, level}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customization = useSelector((state) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
    const {login_Session} = useSelector((state) => state.auth);

    const Icon = item.icon;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
            }}
            fontSize={level > 0 ? "inherit" : "medium"}
        />
    );

    let itemTarget = "_self";
    if (item.target) {
        itemTarget = "_blank";
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />),
    };
    if (item?.external) {
        listItemProps = {component: "a", href: item.url, target: itemTarget};
    }

    const itemHandler = (id) => {
        dispatch({type: MENU_OPEN, id});
        if (matchesSM) dispatch({type: SET_MENU, opened: false});
        if (id === "logout") {
            dispatch(logout(navigate));
        }
        if (id === "gokyc") {
            if (
                window.location.hostname === "https://dashboard.blackcardcoin.com" ||
                window.location.hostname === "dashboard.blackcardcoin.com"
            ) {
                if (login_Session) {
                    window.open(
                        `https://card.blackcardcoin.com/signup?rc=${login_Session?.kyc_key}&email=${login_Session?.email}`,
                        "_blank"
                    );
                }
            } else {
                window.open(`https://black-qa.quikipay.com`, "_blank");
            }
        }
    };
    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split("/")
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({type: MENU_OPEN, id: item.id});
        }
        // eslint-disable-next-line
    }, []);

    function SideTitleTranslate(id) {
        switch (id) {
            case "dashboard":
                return t("dashboard");
            case "myWallet":
                return t("my_wallet");
            case "history":
                return t("history");
            case "buybbc":
                return t("stack_bccoin");
            case "about":
                return t("about_us");
            case "referral":
                return t("referrals");
            case "referrals/history":
                return t("history");
            case "referrals/share":
                return t("share");
            case "gokyc":
                return t("go_kyc");

            case "withdrawal_addresses":
                return t("withdrawal_addresses");

            case "alluser":
                return t("all_users");
            case "alltransactions":
                return t("all_trans");
            case "socialshares":
                return t("social_shares");
            case "customInvoices":
                return t("custom_invo");
            case "notificationSubscribedUsers":
                return t("notifications");
            case "add_investor":
                return t("add_investor");
            case "role":
                return t("role");
            case "settlement":
                return t("settlement");
            case "logout":
                return t("logout");

            case "bank_dashboard":
                return t("dashboard");
            case "bank_mycard":
                return t("my_card");
            case "bank_deposits":
                return t("deposits");
            case "bank_transactions":
                return t("transactions");
            case "bank_kyc_verify":
                return t("kyc_verify");
            case "claim_history":
                return t("claim_history");
            case "referral-tree":
                return t("referral-tree");
            case "allclaims":
                return t("all_claims");
            case "bccanalytics":
                return t("analytics");
            case "comission_managment":
                return t("comission_managment");
            case "perks":
                return t("perks");
            case "orders":
                return t("orders");
            case "migrateuser":
                return t("migrate_user");
            default:
                return item.title;
        }
    }

    return (
        <>
            {item.id === "bank_kyc_reg" ? (
                login_Session?.chUserId == null || login_Session?.chUserId == "" ? (
                    <ListItemButton
                        {...listItemProps}
                        disabled={item.disabled}
                        sx={{
                            borderRadius: `${customization.borderRadius}px`,
                            mb: 0.5,
                            alignItems: "flex-start",
                            backgroundColor: level > 2 ? "transparent !important" : "inherit", // after
                            // backgroundColor: level > 1 ? "transparent !important" : "inherit", // before
                            py: level > 1 ? 1 : 1.25,
                            pl: `${level * 24}px`,
                        }}
                        selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
                        onClick={() => itemHandler(item.id)}
                    >
                        <ListItemIcon sx={{my: "auto", minWidth: !item?.icon ? 18 : 36, color: "#F4F4F4 !important"}}>
                            {itemIcon}
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? "h5" : "body1"}
                                    color="#F4F4F4"
                                >
                                    {SideTitleTranslate(item.id)}
                                </Typography>
                            }
                            secondary={
                                item.caption && (
                                    <Typography variant="caption" sx={{...theme.typography.light}} display="block" gutterBottom>
                                        {item.caption}
                                    </Typography>
                                )
                            }
                        />
                        {item.chip && (
                            <Chip
                                color={item.chip.color}
                                variant={item.chip.variant}
                                size={item.chip.size}
                                label={item.chip.label}
                                avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                            />
                        )}
                    </ListItemButton>
                ) : (
                    ""
                )
            ) : login_Session?.invester_type === "starter" ? (
                item.id === "claim_history" ? (
                    ""
                ) : (
                    <ListItemButton
                        {...listItemProps}
                        disabled={item.disabled}
                        sx={{
                            borderRadius: `${customization.borderRadius}px`,
                            mb: 0.5,
                            alignItems: "flex-start",
                            backgroundColor: level > 2 ? "transparent !important" : "inherit", // after
                            // backgroundColor: level > 1 ? "transparent !important" : "inherit", // before
                            py: level > 1 ? 1 : 1.25,
                            pl: `${level * 24}px`,
                        }}
                        selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
                        onClick={() => itemHandler(item.id)}
                    >
                        <ListItemIcon sx={{my: "auto", minWidth: !item?.icon ? 18 : 36, color: "#F4F4F4 !important"}}>
                            {itemIcon}
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? "h5" : "body1"}
                                    color="#F4F4F4"
                                >
                                    {SideTitleTranslate(item.id)}
                                </Typography>
                            }
                            secondary={
                                item.caption && (
                                    <Typography variant="caption" sx={{...theme.typography.light}} display="block" gutterBottom>
                                        {item.caption}
                                    </Typography>
                                )
                            }
                        />
                        {item.chip && (
                            <Chip
                                color={item.chip.color}
                                variant={item.chip.variant}
                                size={item.chip.size}
                                label={item.chip.label}
                                avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                            />
                        )}
                    </ListItemButton>
                )
            ) : (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    className="hover:text-[#CBF065]"
                    sx={{
                        "&:hover": {
                            color: "#CBF065 !important",
                        },
                        borderRadius: `${0}px`,
                        mb: 0.5,
                        alignItems: "flex-start",
                        backgroundColor: level > 2 ? "transparent !important" : "inherit", // after
                        // backgroundColor: level > 1 ? "transparent !important" : "inherit", // before
                        py: level > 1 ? 1 : 1.25,
                        pl: `${level * 24}px`,
                    }}
                    selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
                    onClick={() => itemHandler(item.id)}
                >
                    <ListItemIcon sx={{my: "auto", minWidth: !item?.icon ? 18 : 36, color: "#F4F4F4 !important"}}>{itemIcon}</ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography
                                variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? "h5" : "body1"}
                                // color="#F4F4F4"
                            >
                                {SideTitleTranslate(item.id)}
                            </Typography>
                        }
                        secondary={
                            item.caption && (
                                <Typography variant="caption" sx={{...theme.typography.light}} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )
                        }
                    />
                    {item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            )}
        </>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number,
};

export default NavItem;
