import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import secureLocalStorage from "react-secure-storage";

const locallanguage = JSON.parse(secureLocalStorage.getItem("language"));

const resources = {
    en: {
        translation: locallanguage?.en?.translation || {},
    },
    es: {
        translation: locallanguage?.es?.translation || {},
    },
    ar: {
        translation: locallanguage?.ar?.translation || {},
    },
    fl: {
        translation: locallanguage?.fl?.translation || {},
    },
    fr: {
        translation: locallanguage?.fr?.translation || {},
    },
    ru: {
        translation: locallanguage?.ru?.translation || {},
    },
    tr: {
        translation: locallanguage?.tr?.translation || {},
    },
    zh: {
        translation: locallanguage?.zh?.translation || {},
    },
    de: {
        translation: locallanguage?.de?.translation || {},
    },
};

const Selectedlang = localStorage.getItem("i18nextLng") === "en-US" ? "en" : localStorage.getItem("i18nextLng");
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: Selectedlang,
        fallbackLng: Selectedlang,
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
        },
    });

export default i18n;
