import axios from "axios";
axios.defaults.withCredentials = true;
import {LOCALHOST_BASE_URL, BASE_URL, QA_URL, PUBLIC_KEY, QA_PUBLIC_KEY} from "./GlobalURLs";
import secureLocalStorage from "react-secure-storage";

import {toast} from "react-hot-toast";

let token = secureLocalStorage.getItem("token");
let lang = localStorage.getItem("i18nextLng");
let Refreshtoken = secureLocalStorage.getItem("refreshToken");

let url;
let merchant_key;

if (window.location.hostname === "https://dashboard.blackcardcoin.com" || window.location.hostname === "dashboard.blackcardcoin.com") {
    url = BASE_URL;
    merchant_key = PUBLIC_KEY;
} else {
    url = QA_URL;
    merchant_key = QA_PUBLIC_KEY;
}

const instance = axios.create({
    baseURL: url,
    headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
        language: lang,
        "x-merchant-public-key": merchant_key,
    },
});

let isRefreshing = false;
let failedQueue = [];

export const refreshToken = () => {
    return new Promise((resolve, reject) => {
        let data = {refreshToken: Refreshtoken};

        axios
            .post(url + "users/refresh-token", data, {
                headers: {
                    "x-merchant-public-key": merchant_key,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    secureLocalStorage.setItem("token", res.data.token);
                    document.dispatchEvent(new CustomEvent("tokenRefreshed", {detail: {token: res.data.token}}));
                    resolve(res.data.token);
                } else {
                    // toast.error(res.data.message);
                    reject(res.data.message);
                }
            })
            .catch((error) => {
                // toast.error(error.response.data.message);
                window.location.href = "/login";
                reject(error);
            });
    });
};

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const refreshAuthLogic = (failedRequest) => {
    return new Promise((resolve, reject) => {
        refreshToken()
            .then((token) => {
                secureLocalStorage.setItem("token", token); // Save new token
                instance.defaults.headers["x-access-token"] = token;
                failedRequest.response.config.headers["x-access-token"] = token;
                resolve(axios(failedRequest.response.config));
            })
            .catch((error) => {
                reject(error);
            });
    });
};

// Interceptor to set the latest token from secureLocalStorage before each request
instance.interceptors.request.use(
    (config) => {
        if (token) {
            config.headers["x-access-token"] = token;
        }
        if (lang) {
            config.headers["language"] = lang;
        } else {
            config.headers["language"] = "en";
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        try {
            const originalRequest = error?.config;
            if (originalRequest) {
                if (error?.response?.status === 401 && !originalRequest?._retry) {
                    if (isRefreshing) {
                        return new Promise((resolve, reject) => {
                            failedQueue.push({resolve, reject});
                        })
                            .then((token) => {
                                originalRequest.headers["x-access-token"] = token;
                                return axios(originalRequest);
                            })
                            .catch((err) => {
                                return Promise.reject(err);
                            });
                    }

                    originalRequest._retry = true;
                    isRefreshing = true;

                    return new Promise((resolve, reject) => {
                        refreshAuthLogic(originalRequest)
                            .then((res) => {
                                processQueue(null, res.data.token);
                                resolve(res);
                            })
                            .catch((err) => {
                                processQueue(err, null);
                                reject(err);
                            })
                            .finally(() => {
                                isRefreshing = false;
                            });
                    });
                }
            }

            return Promise.reject(error);
        } catch (err) {}
    }
);

// Event listener to reload the page when the token is refreshed
document.addEventListener("tokenRefreshed", () => {
    window.location.reload();
});

export default instance;
