import toast from "react-hot-toast";
import Axios from "../../utils/Axios";
import {changeflag} from "../loader/loader.action";

import {GET_PERKS, UPDATE_PERK, CREATE_PERKS} from "../actions";

export const getPerks = (query) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.get(`/perk/list?${query}`)
        .then((res) => {
            dispatch({
                type: GET_PERKS,
                payload: res.data,
            });
            dispatch(changeflag(false));
        })
        .catch((error) => {
            dispatch(changeflag(false));
            console.log("error", error);
        });
};

export const createPerk = (data) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.post(`/perk/create` , data)
        .then((res) => {
            toast.success(res.data.message);
            dispatch(getPerks());
            dispatch(changeflag(false));

        })
        .catch((error) => {
            dispatch(changeflag(false));
            console.log("error", error);
        });
};

export const updatePerk = (data, id) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.put(`/perk/update/${id}` , data)
        .then((res) => {
            dispatch(changeflag(false));
            toast.success(res.data.message);
            dispatch(getPerks());
            dispatch(changeflag(false));

        })
        .catch((error) => {
            dispatch(changeflag(false));
            console.log("error", error);
        });
};


export const deletePerk = (id) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.delete(`/perk/delete/${id}`)
        .then((res) => {
            dispatch({
                type: GET_PERKS,
                payload: res.data,
            });
            dispatch(getPerks());
            toast.success(res.data.message);
        })
        .catch((error) => {
            dispatch(changeflag(false));
            console.log("error", error);
        });
};


