import React, {useEffect, useState} from "react";
import {BASE_URL, LOCALHOST_BASE_URL, QA_URL, PUBLIC_KEY, QA_PUBLIC_KEY} from "../utils/GlobalURLs";

import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
axios.defaults.withCredentials = true;
import {GET_LOGIN_DATA, GET_LANGUAGE} from "../store/actions";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import App from "App";

//i18n
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Rolling from "../assets/images/icons/Rolling.gif";
import secureLocalStorage from "react-secure-storage";
import Lottie from "react-lottie";
import animation from "../assets/pre_loader.json";

const AppLayout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const { Authenticator_status } = useSelector((state) => state.loader);
    const [isAuthenticated, setisAuthenticated] = useState(false);
    const token = secureLocalStorage.getItem("token");

    const loginControl = async () => {
        let url;
        let merchant_key;
        let local_lang = localStorage.getItem("i18nextLng");

        if (
            window.location.hostname === "https://dashboard.blackcardcoin.com" ||
            window.location.hostname === "dashboard.blackcardcoin.com"
        ) {
            url = BASE_URL;
            merchant_key = PUBLIC_KEY;
        } else {
            url = QA_URL;
            merchant_key = QA_PUBLIC_KEY;
        }

        await axios
            .get(`${url}language/lan`, {
                headers: {
                    "x-merchant-public-key": merchant_key,
                },
            })
            .then(async (response) => {
                secureLocalStorage.setItem("language", JSON.stringify(response.data));
                const language = response.data;
                dispatch({
                    type: GET_LANGUAGE,
                    payload: response.data,
                });

                const Selectedlang = localStorage.getItem("i18nextLng") === "en-US" ? "en" : localStorage.getItem("i18nextLng");

                const resources = {
                    en: {
                        translation: language?.en?.translation || {},
                    },
                    es: {
                        translation: language?.es?.translation || {},
                    },
                    ar: {
                        translation: language?.ar?.translation || {},
                    },
                    fl: {
                        translation: language?.fl?.translation || {},
                    },
                    fr: {
                        translation: language?.fr?.translation || {},
                    },
                    ru: {
                        translation: language?.ru?.translation || {},
                    },
                    tr: {
                        translation: language?.tr?.translation || {},
                    },
                    zh: {
                        translation: language?.zh?.translation || {},
                    },
                    de: {
                        translation: language?.de?.translation || {},
                    },
                };

                i18n.use(LanguageDetector)
                    .use(initReactI18next)
                    .init({
                        resources,
                        lng: Selectedlang,
                        fallbackLng: Selectedlang,
                        interpolation: {
                            escapeValue: false,
                        },
                        react: {
                            wait: true,
                        },
                    });

                if (token != null) {
                    try {
                        const res = await axios.get(`${url}users/authenticate`, {
                            headers: {
                                "Content-Type": "application/json",
                                "x-access-token": token,
                                language: local_lang,
                                "x-merchant-public-key": merchant_key,
                            },
                        });

                        const user_data = res.data.user;
                        dispatch({
                            type: GET_LOGIN_DATA,
                            payload: user_data,
                        });

                        setisAuthenticated(true);
                        if (isUnRestrictedRoute(window.location.pathname)) {
                            if (user_data?.isSuperadmin) {
                                navigate("/admin-dashboard");
                            } else {
                                navigate("/dashboard");
                            }
                        }
                    } catch (err) {
                        if (err?.response?.status === 401 || err?.response?.data?.message == "Unauthorized") {
                            try {
                                const refreshToken = secureLocalStorage.getItem("refreshToken");
                                const data = {refreshToken: refreshToken};
                                const res = await axios.post(`${url}users/refresh-token`, data, {
                                    headers: {
                                        language: local_lang,
                                        "x-merchant-public-key": merchant_key,
                                    },
                                });
                                if (res.data.success) {
                                    secureLocalStorage.setItem("token", res?.data?.token);
                                    window?.location?.reload();
                                } else {
                                    UnAuth();
                                }
                            } catch (e) {
                                UnAuth();
                            }
                        } else if (err?.message) {
                            toast.error(err?.message);
                        } else {
                            toast.error(err);
                        }
                    }
                } else {
                    UnAuth();
                }
            })
            .catch((err) => {
                console.log(err);
                if (err?.response?.status === 401 || err?.response?.data?.message == "Unauthorized") {
                    UnAuth();
                } else if (err?.message) {
                    toast.error(err?.message);
                } else {
                    toast.error(err);
                }
            });
    };

    useEffect(() => {
        localStorage.setItem("i18nextLng", localStorage.getItem("i18nextLng") || getcurrentlanguage());
        loginControl();
    }, []);

    function getcurrentlanguage() {
        const str = navigator.language;
        console.log(str, "language");

        if (str.includes("-")) {
            return str.split("-")[0];
        } else {
            return str;
        }
    }

    // useEffect(() => {
    //   setisAuthenticated(Authenticator_status);
    // }, [Authenticator_status]);

    function UnAuth() {
        let pathname = window.location.pathname;

        if (pathname === "/login") {
            // navigate("/login");
        }
        // else if (pathname === "/register") {
        //     navigate("/register");
        // }
        else if (pathname === "/forgotpassword") {
            navigate("/forgotpassword");
        } else if (pathname === "/resetpassword") {
            navigate("/resetpassword");
        } else if (pathname === "/verify-login") {
            navigate("/verify-login");
        } else if (pathname === "/xt-register") {
            // navigate("/resetpassword");
        } else if (pathname === "/xt-register") {
            navigate("/xt-register");
        } else if (pathname === "/otp-generate") {
            navigate("/otp-generate");
        } else if (pathname === "/reset-confirmation") {
            // navigate("/resetpassword");
        } else {
            navigate("/login");
        }
    }

    const isUnRestrictedRoute = (pathname) => {
        return (
            pathname === "/login" ||
            // pathname === "/register" ||
            pathname === "/forgotpassword" ||
            pathname === "/resetpassword" ||
            pathname === "/verify-login" ||
            pathname === "/xt-register" ||
            pathname === "/otp-generate" ||
            pathname === "/reset-confirmation"
        );
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return isUnRestrictedRoute(window.location.pathname) ? (
        <App />
    ) : (
        <>
            {isAuthenticated ? (
                <App />
            ) : (
                //Animation For Loading
                <div className="relative">
                    <div className="flex items-center justify-center h-[100vh] bg-[#1B1B1B]">
                        {/* <img src={Rolling} width={"100"} alt="Loading...." /> */}
                        <Lottie options={defaultOptions} height={150} width={150} />
                    </div>
                </div>
            )}
        </>
    );
};
export default AppLayout;
