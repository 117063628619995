import { 
  GET_NOTIFICATIONS
} from '../actions';

const initialState = {
  subscribedUsers: null
};

export default function (state = initialState, action) {
  switch (action.type) {

    case GET_NOTIFICATIONS:
      return {
        ...state,
        subscribedUsers: action.payload,
      };

    default:
      return state;
  }
}
