import {useSelector} from "react-redux";
import {ThemeProvider} from "@mui/material/styles";
import {CssBaseline, StyledEngineProvider} from "@mui/material";
import {GoogleOAuthProvider} from "@react-oauth/google";
// import { grantPermission } from "notification";
// routing
import Routes from "routes";
// defaultTheme
import themes from "themes";
// project imports
import NavigationScroll from "layout/NavigationScroll";
import {useEffect} from "react";

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const {login_Session} = useSelector((state) => state.auth);

    if (window.location.hostname === "https://dashboard.blackcardcoin.com" || window.location.hostname === "dashboard.blackcardcoin.com") {
        console.log = function () {};
        console.warn = function () {};
        console.error = function () {};
        console.info = function () {};
    }

    if (process.env.NODE_ENV === "production") {
        console.log = function () {};
        console.warn = function () {};
        console.error = function () {};
        console.info = function () {};
    }

    useEffect(() => {
        if (
            window.location.hostname === "https://dashboard.blackcardcoin.com" ||
            window.location.hostname === "dashboard.blackcardcoin.com"
        ) {
            hideConsole();
        }

        if (login_Session && login_Session?.isSuperadmin === false) {
            createWhatsAppWidget("+447537147937");
        }
    }, []);

    function hideConsole() {
        var head = document.head || document.getElementsByTagName("head")[0];

        var script = document.createElement("script");
        script.appendChild(
            document.createTextNode(
                `
              document.addEventListener('contextmenu', (e) => e.preventDefault());
      
              function ctrlShiftKey(e, keyCode) {
                  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
              }
      
              document.onkeydown = (e) => {
                  if (
                      event.keyCode === 123 ||
                      ctrlShiftKey(e, 'I') ||
                      ctrlShiftKey(e, 'J') ||
                      ctrlShiftKey(e, 'C') ||
                      (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
                  )
                      return false;
              };
              console.log = function () { };
              `
            )
        );
        head.appendChild(script);
    }

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </GoogleOAuthProvider>
    );
};

export default App;

function createWhatsAppWidget(number = "+447537147937") {
    // Create the anchor element
    const anchor = document.createElement("a");

    // Set href, class, target, and rel attributes
    anchor.setAttribute("href", "https://wa.me/" + number);
    anchor.setAttribute("class", "whatsapp_float");
    anchor.setAttribute("target", "_blank");
    anchor.setAttribute("rel", "noopener noreferrer");

    // Create the icon element
    const icon = document.createElement("i");
    icon.setAttribute("class", "fa fa-whatsapp whatsapp-icon");
    anchor.appendChild(icon);
    document.body.appendChild(anchor);
}
