import {GET_LOGIN_DATA, GET_FORGOT_DATA, GET_ROLE_LISTNG} from "../actions";
import toast from "react-hot-toast";
import Axios from "../../utils/Axios";
import {changeflag, dataLoader} from "../loader/loader.action";
import {addSocialInfo} from "store/referral/referral.action";
import secureLocalStorage from "react-secure-storage";


export const login = (signInData, setopenModal) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.post("users/login", signInData)
        .then((res) => {
            if (res.data.success) {
                toast.success(res.data.message);
                // dispatch({
                //     type: GET_LOGIN_DATA,
                //     payload: res.data.user,
                // });
                dispatch(changeflag(false));
                setopenModal(true);
                // setTimeout(function () {
                //     dispatch(changeflag(false));
                //     if (res.data.user.isSuperadmin == true && res.data.user.role == 1) {
                //         navigate("/admin-dashboard", {replace: true});
                //     } else if (res?.data?.user?.role == 2) {
                //         navigate("/role-dashboard", {replace: true});
                //     } else if (res?.data?.user?.role == 6) {
                //         navigate("/dashboard", {replace: true});
                //     } else {
                //         toast.error("No Role Found!");
                //     }
                //     navigate(0);
                // }, 1000);
            } else {
                toast.error(res.data.message);

                if (res?.data?.reset_password) {
                    dispatch({
                        type: GET_FORGOT_DATA,
                        payload: res.data.email,
                    });
                    setTimeout(function () {
                        // navigate("/resetpassword");
                        dispatch(changeflag(false));
                    }, 4000);
                } else {
                    dispatch(changeflag(false));
                }
            }
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const verifyEmailOTP = (verifyOTPData, navigate) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.post("users/verifyOTP", verifyOTPData)
        .then((res) => {
            if (res.data.success) {
                toast.success(res.data.message);
                secureLocalStorage.setItem("token", res.data.token);
                secureLocalStorage.setItem("refreshToken", res.data.refreshToken);

                let userData = res.data.user;
                dispatch({
                    type: GET_LOGIN_DATA,
                    payload: res.data.user,
                });
                dispatch(changeflag(false));

                if (userData && userData?.user_lang && userData?.user_lang != "") {
                    let lang = userData?.user_lang;
                    localStorage.setItem("i18nextLng", lang);
                }

                setTimeout(function () {
                    dispatch(changeflag(false));
                    if (res.data.user?.isSuperadmin == true && res.data.user.role == 1) {
                        navigate("/admin-dashboard", {replace: true});
                    } else if (res?.data?.user?.role == 2) {
                        navigate("/role-dashboard", {replace: true});
                    } else if (res?.data?.user?.role == 6) {
                        navigate("/dashboard", {replace: true});
                    } else {
                        toast.error("No Role Found!");
                    }
                    navigate(0);
                }, 2000);
            } else {
                if (res?.data?.reset_password) {
                    toast(res.data.message, {
                        duration: 8000,
                    });

                    dispatch({
                        type: GET_FORGOT_DATA,
                        payload: res.data.email,
                    });
                    setTimeout(function () {
                        // navigate("/resetpassword");
                        dispatch(changeflag(false));
                    }, 4000);
                } else {
                    toast.error(res.data.message);
                    dispatch(changeflag(false));
                }
            }
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const Register = (signInData, navigate, type) => (dispatch) => {
    // dispatch(changeflag(true));
    Axios.post("users/register", signInData)
        .then((res) => {
            let data = res.data;
            // secureLocalStorage.setItem("token", data.token);
            toast.success(data.message);
            dispatch({
                type: GET_LOGIN_DATA,
                payload: data.user,
            });

            if (type === "xt") {
                navigate(
                    "/otp-generate",
                    {
                        state: {email: signInData?.email},
                    },
                    {replace: true}
                );
            } else {
                navigate("/dashboard", {replace: true});
                navigate(0);
            }

            // dispatch(changeflag(false));
        })
        .catch((error) => {
            // dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const forgot = (data, navigate) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.post("users/fogotPassword", data)
        .then((res) => {
            if (res.data.error == false) {
                toast.success(res.data.message, {
                    duration: 3000,
                });

                dispatch({
                    type: GET_FORGOT_DATA,
                    payload: data.email,
                });
                navigate("/reset-confirmation", {state: data});
            } else {
                toast.error(res.data.message);
            }
            dispatch(changeflag(false));
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.toJSON().message === "Network Error") {
                alert("No internet connection");
                navigate(0);
            }
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const resetpassword = (data, navigate) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.put("users/resetpassword", data)
        .then((res) => {
            if (res.data.success == true) {
                toast.success(res.data.message);
                navigate("/login");
            } else {
                toast.error(res.data.message);
            }
            dispatch(changeflag(false));
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const changepassword = (data, navigate) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.post("users/changepassword", data)
        .then((res) => {
            toast.success(res.data.message);
            navigate("/dashboard");
            dispatch(changeflag(false));
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const authConfig = () => (dispatch) => {
    Axios.get("users/authenticate")
        .then((res) => {
            dispatch({
                type: GET_LOGIN_DATA,
                payload: res.data.user,
            });
        })
        .catch((err) => {});
};

export const logout = (navigate) => (dispatch) => {
    dispatch(changeflag(true));
    try {
        secureLocalStorage.removeItem("token");
        secureLocalStorage.removeItem("paydata");
        secureLocalStorage.removeItem("refreshToken");

        // dispatch({
        //   type: GET_LOGIN_DATA,
        //   payload: {},
        // });

        toast.success("Logout Successfully");

        setTimeout(function () {
            dispatch(changeflag(false));
            navigate(0);
        }, 1000);
    } catch (error) {
        dispatch(changeflag(false));
        if (error.response) {
            toast.error(error?.response?.data?.message);
        } else if (error.request) {
            toast.error(error?.request);
        } else {
            toast.error(error?.message);
        }
    }
};

export const GetUserByID = (ID) => (dispatch) => {
    Axios.get("user/" + ID)
        .then((res) => {
            dispatch({
                type: GET_LOGIN_DATA,
                payload: res.data,
            });
            // }
        })
        .catch((error) => {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const UpdateUserProfile = (data, id, navigate) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.put("users/" + id, data)
        .then((res) => {
            if (res.status === 200) {
                toast.success(res.data.message);
                if (res.data?.user) {
                    dispatch({
                        type: GET_LOGIN_DATA,
                        payload: res.data.user,
                    });
                }
            } else {
                toast.error(res.data.message);
            }
            dispatch(changeflag(false));
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const UploadUserImage = (formData, data, status, id, navigate) => async (dispatch) => {
    dispatch(changeflag(true));
    console.log(formData);
    Axios.post("user/upload", formData, {
        headers: {"Content-Type": "multipart/form-data"},
    })
        .then((res) => {
            if (res.status === 200 && data) {
                let path = res.data?.files?.image?.path;
                data["image"] = path;

                if (status == "UpdateProfile") {
                    dispatch(UpdateUserProfile(data, id));
                } else if (status == "addSocialInfo") {
                    dispatch(addSocialInfo(data));
                }
                dispatch(changeflag(false));
            } else {
                dispatch(changeflag(false));
                toast.error(res.data.message);
            }
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            dispatch(changeflag(false));

            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const UploadImage = (formData) => async (dispatch) => {
    try {
        const response = await Axios.post("user/upload", formData, {
            headers: {"Content-Type": "multipart/form-data"},
        });

        if (response.status === 200) {
            toast.success(response.data.message);

            return response.data.files; // Return the response data
        }
    } catch (error) {
        console.log(error);
        if (error.response) {
            toast.error(error?.response?.data?.message);
        } else if (error.request) {
            toast.error(error?.request);
        } else {
            toast.error(error?.message);
        }

        throw error; // Throw the error to be caught in the handleFileChange function
    }
};

export const sendVerificationOTP = (data) => async () => {
    try {
        const response = await Axios.post("users/sendOTP", data);
        const responseData = response.data;

        if (!responseData.error) {
            toast.success("OTP sent successfully. Please check your email.");
        } else {
            toast.error(responseData.message);
        }

        return responseData; // Return response data
    } catch (error) {
        if (error.response) {
            toast.error(error?.response?.data?.message);
        } else if (error.request) {
            toast.error(error?.request);
        } else {
            toast.error(error?.message);
        }

        throw error; // Throw error to be caught by caller
    }
};

export const verifyOTP = (data) => async () => {
    try {
        const response = await Axios.put("users/setpassword", data);
        const responseData = response.data;

        if (!responseData.error) {
            toast("Email is verified successfully.");
        } else {
            toast.error(responseData.message);
        }

        return responseData; // Return response data
    } catch (error) {
        if (error.response) {
            toast.error(error?.response?.data?.message);
        } else if (error.request) {
            toast.error(error?.request);
        } else {
            toast.error(error?.message);
        }

        throw error; // Throw error to be caught by caller
    }
};

export const GetRoleListing = () => (dispatch) => {
    dispatch(changeflag(true));
    Axios.get("users/role")
        .then((res) => {
            dispatch({
                type: GET_ROLE_LISTNG,
                payload: res.data,
            });
            dispatch(changeflag(false));
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const RegisterRoleUser = (signInData, setClose) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.post("users/addrole", signInData)
        .then((res) => {
            if (res.data.success) {
                setClose(false);
                if (res?.data?.otp_verified === false) {
                    setClose(true);
                } else {
                    setClose(false);
                }
                let data = res.data;
                toast.success(data.message);
                dispatch(GetRoleListing());
                dispatch(changeflag(false));
            }
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const CHRegister = (signInData, navigate) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.post("bank/registerIntoCardholder", signInData)
        .then((res) => {
            dispatch({
                type: GET_LOGIN_DATA,
                payload: res.data.user,
            });
            if (res.data.message) {
                toast.success(res.data.message);
                setTimeout(() => {
                    navigate("/bank/kyc-verify");
                }, 1000);
            }
            dispatch(changeflag(false));
        })
        .catch((error) => {
            console.log(error);
            dispatch(changeflag(false));
            if (error.response.data) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};
