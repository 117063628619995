import PropTypes from "prop-types";
// material-ui
import {useTheme} from "@mui/material/styles";
import {Avatar, Box, ButtonBase, Button, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch} from "react-redux";
// project imports
import LogoSection from "../LogoSection";
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";
import LanguageSection from "./LanguageSection";
// assets
import {IconMenu2} from "@tabler/icons";
import {useNavigate} from "react-router-dom";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({handleLeftDrawerToggle}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const {login_Session} = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const {t} = useTranslation();
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: "flex",
                    [theme.breakpoints.down("md")]: {
                        width: "auto",
                    },
                }}
            >
                <Box component="span" sx={{display: {xs: "none", md: "block"}, flexGrow: 1}}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{borderRadius: "12px", overflow: "hidden"}}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: "all .2s ease-in-out",
                            background: "#000",
                            color: theme.palette.secondary.light,
                            "&:hover": {
                                // background: "#B9FC3E",
                                color: theme.palette.secondary.light,
                            },
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            {/* header search */}

            <Box sx={{flexGrow: 1}} />
            <Box sx={{flexGrow: 1}} />
            {/* notification & profile */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#000",
                    borderRadius: "50px",
                    padding: "4px",
                    paddingLeft: "4px",
                    border: "1px solid #484848",
                }}
            >
                {!(isSmallScreen || login_Session?.isSuperadmin) && (
                    <Button
                        type="button"
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/utils/buy/coins")}
                        className="w-[246px] h-[40px] bg-bcprimary hover:bg-[#97ce32] text-[#000] disabled:text-white disabled:cursor-not-allowed disabled:bg-[#8176FF] rounded-[21px] whitespace-nowrap"
                    >
                        {t("stake_now")}
                    </Button>
                )}
                &nbsp;&nbsp;
                {/* <NotificationSection /> */}
                <LanguageSection backgroundColor="#000" paddingtop="5px" paddingbottom="5px" />
                &nbsp;&nbsp;&nbsp;
                <ProfileSection />
            </Box>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
