import React from "react";
import {useState, useEffect} from "react";
// @mui
import {alpha} from "@mui/material/styles";
import {Box, MenuItem, Stack, IconButton, Popover, Select} from "@mui/material";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
// Flags png
import EnFlag from "../../../../assets/flags/usa-flag.svg";
import TrFlag from "../../../../assets/flags/tr.png";
import EsFlag from "../../../../assets/flags/es.png";
import ChinaFlag from "../../../../assets/flags/china.svg";
import FranceFlag from "../../../../assets/flags/France.svg";
import RussiaFlag from "../../../../assets/flags/Russia.svg";
import UAEFlag from "../../../../assets/flags/Arabic.svg";
import germanFlag from "../../../../assets/flags/german.svg";

// ----------------------------------------------------------------------

const useStyles = makeStyles({
    icon: {
        fill: "white",
    },
    root: {
        color: "white",
    },
});

const LANGS = [
    {
        value: "en",
        label: "English",
        icon: EnFlag,
    },
    {
        value: "tr",
        label: "Turkish",
        icon: TrFlag,
    },
    {
        value: "es",
        label: "Spanish",
        icon: EsFlag,
    },
    {
        value: "ru",
        label: "Russia",
        icon: RussiaFlag,
    },
    {
        value: "ar",
        label: "Arabic",
        icon: UAEFlag,
    },
    {
        value: "zh",
        label: "Chinese",
        icon: ChinaFlag,
    },
    {
        value: "fr",
        label: "French",
        icon: FranceFlag,
    },
    {
        value: "fl",
        label: "Flamenco",
        icon: EsFlag,
    },

    {
        value: "de",
        label: "German",
        icon: germanFlag,
    },
];

// ----------------------------------------------------------------------

export default function LanguagePopover({backgroundColor, paddingtop, paddingbottom}) {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const [selectlang, setSelectlang] = useState(
        localStorage.getItem("i18nextLng") === "en-US" ? "en" : localStorage.getItem("i18nextLng")
    );

    useEffect(() => {
        const localLang = localStorage.getItem("i18nextLng") === "en-US" ? "en" : localStorage.getItem("i18nextLng");
        if (localLang) {
            setSelectlang(localLang);
        }
    }, [selectlang]);

    const changeLanguage = (e) => {
        const lng = e.target.value;
        i18n.changeLanguage(lng);
        setSelectlang(lng);
    };

    // backgroundColor="#000" paddingtop="5px" paddingbottom="5px"
    const dynamicStyles = {};
    if (backgroundColor) {
        console.log(backgroundColor, "backgroundColor");
        dynamicStyles.background = backgroundColor;
        dynamicStyles[
            "& .css-1uqk7os-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary"
        ] = {
            background: backgroundColor,
        };
        dynamicStyles[
            "& .css-1s00olg-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1s00olg-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1s00olg-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input"
        ] = {
            background: backgroundColor,
        };
    }
    if (true) {
        dynamicStyles["&.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary"] = {
            borderRadius: "12px",
        };
        dynamicStyles["& .MuiOutlinedInput-notchedOutline"] = {
            borderColor: "transparent !important",
        };
    }

    return (
        <>
            <Select
                SelectDisplayProps={{style: {paddingTop: paddingtop ? paddingtop : 10, paddingBottom: paddingbottom ? paddingbottom : 10}}}
                value={selectlang}
                onChange={changeLanguage}
                MenuProps={{
                    PaperProps: {
                        style: {
                            backgroundColor: "#2A2A2A",
                            borderRadius: "7px",
                            border: "1px solid #707070",
                            color: "#fff",
                        },
                        sx: {
                            "& .MuiMenuItem-root.Mui-selected": {
                                backgroundColor: "#1B1B1B !important",
                            },
                            "& .MuiMenuItem-root:hover": {
                                backgroundColor: "#1B1B1B",
                            },
                            "& .MuiMenuItem-root.Mui-selected:hover": {
                                backgroundColor: "#1B1B1B",
                            },
                        },
                    },
                }}
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
                sx={{
                    "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                        borderWidth: "1px",
                    },
                    ...dynamicStyles,
                }}
            >
                {LANGS?.map((data, index) => {
                    return (
                        <MenuItem value={data.value} key={index}>
                            <div className="flex items-center gap-2 justify-between w-full">
                                <span>{data.label}</span>
                                <img src={data.icon} className="w-[30px] h-[30px] object-contain" alt="English" />
                            </div>
                        </MenuItem>
                    );
                })}
            </Select>

            {/* <IconButton
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 36,
          height: 36,
          marginRight: '20px',
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={lang.icon} alt={lang.label} />
       </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& .MuiPaper-root': {
            boxShadow: 'none',
            border: '1px solid',
            // backgroundColor: 'transparent',
            borderRadius: 2,
            margin: '2px 4px',
          },
          '& .MuiMenuItem-root': {
            padding: 1,
            typography: 'body2',
            color: 'black',
            fontWeight: 500,
            '&:hover': {
              borderColor: "#6B5EFF",
              background: `#6B5EFF !important`,
              color: 'white',
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem onClick={() => handleChange(option)} key={option.value} value={lang.value}  selected={option.value === lang?.value}>
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, marginRight: 2 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover> */}
        </>
    );
}
