// material-ui
import {useTheme} from "@mui/material/styles";
import logo from "assets/images/icons/logo.png";

// ==============================|| LOGO SVG ||============================== //

const Logo = ({width}) => {
    const theme = useTheme();

    return <img src={logo} alt="BCCoin" width='100' style={{width:width ? width : "" }}/>;
};

export default Logo;
