import { useRoutes } from 'react-router-dom';
// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { useSelector } from 'react-redux';
import adminRoutes from './adminRoutes';
import RoleRoutes from './RoleRoutes';



// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { login_Session } = useSelector((state) => state.auth);

    let Global_route;
    if (login_Session?.role == 1) {
        Global_route = [
            adminRoutes,
            AuthenticationRoutes
        ]
    } else if(login_Session?.role == 2){
        Global_route = [
            RoleRoutes,
            AuthenticationRoutes,
        ]
    }
     else {
        Global_route = [
            MainRoutes,
            AuthenticationRoutes,
        ]
    }

    return useRoutes(
        Global_route
    );
    
}
