import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/BCCadmin/index')));
const AllUsers = Loadable(lazy(() => import('views/pages/AllUsers/Users')));
const AllTransactions = Loadable(lazy(() => import('views/pages/AllTransactions/Transactions')));
const SocialShare = Loadable(lazy(() => import('views/pages/SocialShare/SocialShare')));
const GeneratePaymentLinks = Loadable(lazy(() => import('views/pages/GenerateLinks/GeneratePaymentLinks')));
const Role = Loadable(lazy(() => import('views/pages/AdminsRole/Role')));
const Settlement = Loadable(lazy(() => import('views/pages/settlement/index')));
const Claims = Loadable(lazy(()=>import('views/pages/AllClaims/Claims')))
const Analytics = Loadable(lazy(()=>import('views/pages/bccAnalytics/Analytics')))
const MigratedUsers = Loadable(lazy(()=>import('views/pages/MigrationUser/MigratedUsers')))
const ComissionManagment = Loadable(lazy(()=>import('views/pages/ComissionManagment/ComissionManagment')))


const Profile = Loadable(lazy(() => import('views/pages/Profile/Profile')));
const ChangePass = Loadable(lazy(() => import('views/pages/ChangePassword/ChangePass')));
const GenerateInvoices = Loadable(lazy(() => import('views/pages/GenerateInvoices')));
const Perks = Loadable(lazy(() => import('views/pages/Perks/Perks')));
const Orders = Loadable(lazy(() => import('views/pages/Orders/Orders')));
const NotificationUsers = Loadable(lazy(() => import('views/pages/NotificationUsers/OneSignalDashboard')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'admin-dashboard',
            children: [
                {
                    path: '',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'users',
                    element: <AllUsers />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'transactions',
                    element: <AllTransactions />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'social-shares',
                    element: <SocialShare />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'custom-invoices',
                    element: <GenerateInvoices />
                },
                {
                    path: 'perks',
                    element: <Perks />
                },
                {
                    path: 'orders',
                    element: <Orders />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'claims',
                    element: <Claims />
                }
            ]
        },
        {
            path: 'bccoin',
            children: [
                {
                    path: 'analytics',
                    element: <Analytics />
                }
            ]
        },
        {
            path: 'notification',
            children: [
                {
                    path: 'subscribed-users',
                    element: <NotificationUsers />
                }
            ]
        },
        {
            path: 'add',
            children: [
                {
                    path: 'investor',
                    element: <GeneratePaymentLinks />
                }
            ]
        },
        {
            path: 'create',
            children: [
                {
                    path: 'role',
                    element: <Role />
                }
            ]
        },
        {
            path: 'create',
            children: [
                {
                    path: 'settlement',
                    element: <Settlement />
                }
            ]
        },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: 'change_password',
            element: <ChangePass />
        },
        {
            path: 'migrate',
            children: [
                {
                    path: 'user',
                    element: <MigratedUsers />
                }
            ]
        },
        {
            path: 'comission',
            children: [
                {
                    path: 'managment',
                    element: <ComissionManagment />
                }
            ]
        },
    ]
};

export default MainRoutes;
