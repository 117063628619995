import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
// import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgotPassward = Loadable(lazy(() => import('views/pages/authentication/authentication3/Forgot')));
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword')));
const LoginVerifiation = Loadable(lazy(() => import('views/pages/authentication/authentication3/LoginVerification')));
const SocialShare = Loadable(lazy(() => import('views/pages/SharePage/SocialShare')));
const XTRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/XTRegister3')));
const XTOTP = Loadable(lazy(() => import('views/pages/authentication/authentication3/XTOTP')));
const ResetSuccess = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetSuccessPage')))








// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    // path: '/',
    // element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        // {
        //     path: '/register',
        //     element: <AuthRegister3 />
        // },
        {
            path: '/forgotpassword',
            element: <AuthForgotPassward />
        },
        {
            path: '/resetpassword',
            element: <AuthResetPassword />
        },
        {
            path: '/verify-login',
            element: <LoginVerifiation />
        },
        {
            path: '/social/share',
            element: <SocialShare />
        },
        {
            path: '/xt-register',
            element: <XTRegister3 />
        },
        {
            path: '/otp-generate',
            element: <XTOTP />
        },
        {
            path: '/reset-confirmation',
            element: <ResetSuccess />
        },
    ]
};

export default AuthenticationRoutes;
