import {useState, useRef, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
// material-ui
import {useTheme} from "@mui/material/styles";
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import {IconLogout, IconDotsVertical, IconSettings} from "@tabler/icons";
import {logout} from "../../../../store/auth/auth.action";
import {FaUserEdit} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {isAndroid} from "react-device-detect";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const customization = useSelector((state) => state.customization);
    const {login_Session} = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [openList, setOpenList] = useState(false);

    const anchorRef = useRef(null);

    const handleLogout = async () => {
        dispatch(logout(navigate));
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleCloseList = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenList(false);
    };

    const handleListItemClick = (event, index, route = "") => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== "") {
            navigate(route);
        }
        setOpenList(false)
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleToggleList = () => {
        setOpenList((prevOpenlist) => !prevOpenlist);
    };

    const prevOpen = useRef(open);
    const prevOpenlist = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (prevOpenlist.current === true && openList === false) {
            anchorRef.current.focus();
        }

        prevOpenlist.current = openList;
    }, [openList]);


    const truncateString = (str) => str.length > 10 ? str.substring(0, 10) + '...' : str;

    return (
        <>
            {!isSmallScreen && (
                <>
                    <Typography variant="h4" gutterBottom className="text-sm font-[400] text-[#B9FC3E]">
                        {login_Session && login_Session.userName}
                    </Typography>
                    &nbsp;&nbsp;
                </>
            )}
            <Chip
                sx={{
                    height: "40px", // Adjust height to ensure Avatar fits properly
                    width: "40px", // Adjust width to ensure Avatar fits properly
                    alignItems: "center",
                    borderRadius: "100%", // Make the Chip a perfect circle
                    transition: "all .2s ease-in-out",
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    display: "flex",
                    justifyContent: "center",
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: "transparent",
                        background: `transparent !important`,
                        color: theme.palette.primary.light,
                        "& svg": {
                            stroke: theme.palette.primary.light,
                        },
                    },
                    "& .MuiChip-label": {
                        lineHeight: 0,
                    },
                }}
                icon={
                    <Avatar
                        src={login_Session.image}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: "auto", // Center the Avatar within the Chip
                            // cursor: "pointer",
                            height: "30px",
                            width: "30px",
                            marginLeft: "15px !important",
                        }}
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <ListItemIcon>
                <IconDotsVertical
                    stroke={1.5}
                    size="1.8rem"
                    className="text-[#C4C4C4] cursor-pointer"
                    ref={anchorRef}
                    aria-controls={openList ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggleList}
                />
            </ListItemIcon>
            <Popper
            className="w-[250px]"
                placement="bottom-end"
                open={openList}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 14],
                            },
                        },
                    ],
                }}
            >
                {({TransitionProps}) => (
                    <Transitions in={openList} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseList}>
                                <MainCard
                                    className="bg-bcBoxbg border-[1px] border-solid border-bcborder rounded-[7px]"
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                >
                                    <Box sx={{p: 2}}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4" className="text-white">
                                                    {t("good_morning")},
                                                </Typography>
                                                <Typography
                                                    component="span"
                                                    variant="h4"
                                                    className="capitalize text-bctext"
                                                    sx={{fontWeight: 400}}
                                                >
                                                    {login_Session && truncateString(login_Session?.userName)}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>

                                    <Box
                                        style={{
                                            height: "100%",
                                            maxHeight: "calc(100vh - 250px)",
                                            overflowX: "hidden",
                                            background: "#2A2A2A",
                                        }}
                                    >
                                        <Box sx={{p: 2}} className="bg-bcBoxbg">
                                            <List
                                                component="nav"
                                                sx={{
                                                    background: "#2A2A2A",
                                                    width: "100%",
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    // backgroundColor: theme.palette.background.paper,
                                                    borderRadius: "7px",
                                                    [theme.breakpoints.down("md")]: {
                                                        minWidth: "100%",
                                                    },
                                                    "& .MuiListItemButton-root": {
                                                        mt: 0.5,
                                                    },
                                                }}
                                            >
                                                {!login_Session?.isSuperadmin && (
                                                    <ListItemButton
                                                        size="0.5rem"
                                                        sx={{
                                                            borderRadius: `${customization.borderRadius}px`,
                                                            background: "transparent !important",
                                                        }}
                                                        selected={selectedIndex === 0}
                                                        onClick={(event) => handleListItemClick(event, 0, "/profile")}
                                                    >
                                                        <ListItemIcon>
                                                            <FaUserEdit size="1.3rem" color="#fff" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2" className="text-white hover:text-[#B9FC3E]">
                                                                    {t("profile")}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                )}
                                                <ListItemButton
                                                    sx={{
                                                        borderRadius: `${customization.borderRadius}px`,
                                                        background: "transparent !important",
                                                    }}
                                                    selected={selectedIndex === 1}
                                                    onClick={(event) => {
                                                      
                                                        handleListItemClick(event, 1, "/change_password")
                                                         }}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" color="#fff" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2" className="text-white hover:text-[#B9FC3E]">
                                                                {t("change_password")}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{
                                                        borderRadius: `${customization.borderRadius}px`,
                                                        background: "transparent !important",
                                                    }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" color="#fff" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2" className="text-white hover:text-[#B9FC3E]">
                                                                {t("logout")}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;

// import { useState, useRef, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// // material-ui
// import { useTheme } from "@mui/material/styles";
// import {
//     Avatar,
//     Box,
//     Card,
//     CardContent,
//     Chip,
//     ClickAwayListener,
//     Divider,
//     Grid,
//     InputAdornment,
//     List,
//     ListItemButton,
//     ListItemIcon,
//     ListItemText,
//     OutlinedInput,
//     Paper,
//     Popper,
//     Stack,
//     Switch,
//     Typography,
// } from "@mui/material";
// // third-party
// import PerfectScrollbar from "react-perfect-scrollbar";

// // project imports
// import MainCard from "ui-component/cards/MainCard";
// import Transitions from "ui-component/extended/Transitions";
// import User1 from "assets/images/users/user-round.svg";
// // assets
// import { IconLogout, IconSearch, IconSettings, IconUser ,IconDotsVertical } from "@tabler/icons";
// import { logout } from "../../../../store/auth/auth.action";
// import { FaUserEdit } from "react-icons/fa";
// import { useTranslation } from "react-i18next";

// // ==============================|| PROFILE MENU ||============================== //

// const ProfileSection = () => {
//     const {t} = useTranslation();
//     const theme = useTheme();
//     const customization = useSelector((state) => state.customization);
//     const { login_Session } = useSelector((state) => state.auth);
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const [sdm, setSdm] = useState(true);
//     const [value, setValue] = useState("");
//     const [notification, setNotification] = useState(false);
//     const [selectedIndex, setSelectedIndex] = useState(-1);
//     const [open, setOpen] = useState(false);

//     /**
//      * anchorRef is used on different componets and specifying one type leads to other components throwing an error
//      * */
//     const anchorRef = useRef(null);
//     const handleLogout = async () => {
//         dispatch(logout(navigate));
//     };

//     const handleClose = (event) => {
//         if (anchorRef.current && anchorRef.current.contains(event.target)) {
//             return;
//         }
//         setOpen(false);
//     };

//     const handleListItemClick = (event, index, route = "") => {
//         setSelectedIndex(index);
//         handleClose(event);

//         if (route && route !== "") {
//             navigate(route);
//         }
//     };
//     const handleToggle = () => {
//         setOpen((prevOpen) => !prevOpen);
//     };

//     const prevOpen = useRef(open);
//     useEffect(() => {
//         if (prevOpen.current === true && open === false) {
//             anchorRef.current.focus();
//         }

//         prevOpen.current = open;
//     }, [open]);

//     return (
//         <>
//             <Chip
//                 sx={{
//                     height: "48px",
//                     alignItems: "center",
//                     borderRadius: "27px",
//                     transition: "all .2s ease-in-out",
//                     borderColor: "#6B5EFF",
//                     backgroundColor: "#6B5EFF",
//                     '&[aria-controls="menu-list-grow"], &:hover': {
//                         borderColor: "#6B5EFF",
//                         background: `#6B5EFF !important`,
//                         color: theme.palette.primary.light,
//                         "& svg": {
//                             stroke: theme.palette.primary.light,
//                         },
//                     },
//                     "& .MuiChip-label": {
//                         lineHeight: 0,
//                     },
//                 }}
//                 icon={
//                     <Avatar
//                         src={login_Session.image}
//                         sx={{
//                             ...theme.typography.mediumAvatar,
//                             margin: "8px 0 8px 8px !important",
//                             cursor: "pointer",
//                         }}
//                         ref={anchorRef}
//                         aria-controls={open ? "menu-list-grow" : undefined}
//                         aria-haspopup="true"
//                         color="inherit"
//                     />
//                 }
//                 // label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.light} />}
//                 label={<IconDotsVertical stroke={1.5} size="1.5rem" color={theme.palette.primary.light} />}
//                 variant="outlined"
//                 ref={anchorRef}
//                 aria-controls={open ? "menu-list-grow" : undefined}
//                 aria-haspopup="true"
//                 onClick={handleToggle}
//                 color="primary"
//             />
//             <Popper
//                 placement="bottom-end"
//                 open={open}
//                 anchorEl={anchorRef.current}
//                 role={undefined}
//                 transition
//                 disablePortal
//                 popperOptions={{
//                     modifiers: [
//                         {
//                             name: "offset",
//                             options: {
//                                 offset: [0, 14],
//                             },
//                         },
//                     ],
//                 }}
//             >
//                 {({ TransitionProps }) => (
//                     <Transitions in={open} {...TransitionProps}>
//                         <Paper>
//                             <ClickAwayListener onClickAway={handleClose}>
//                                 <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
//                                     <Box sx={{ p: 2 }}>
//                                         <Stack>
//                                             <Stack direction="row" spacing={0.5} alignItems="center">
//                                                 <Typography variant="h4">{t('good_morning')},</Typography>
//                                                 <Typography component="span" variant="h4" className="capitalize" sx={{ fontWeight: 400 }}>
//                                                     {login_Session && login_Session.userName}
//                                                 </Typography>
//                                             </Stack>
//                                         </Stack>
//                                     </Box>
//                                     <PerfectScrollbar style={{ height: "100%", maxHeight: "calc(100vh - 250px)", overflowX: "hidden" }}>
//                                         <Box sx={{ p: 2 }}>
//                                             <List
//                                                 component="nav"
//                                                 sx={{
//                                                     width: "100%",
//                                                     maxWidth: 350,
//                                                     minWidth: 300,
//                                                     backgroundColor: theme.palette.background.paper,
//                                                     borderRadius: "10px",
//                                                     [theme.breakpoints.down("md")]: {
//                                                         minWidth: "100%",
//                                                     },
//                                                     "& .MuiListItemButton-root": {
//                                                         mt: 0.5,
//                                                     },
//                                                 }}
//                                             >
//                                                 {login_Session?.isSuperadmin === false
//                                                     ?
//                                                     <ListItemButton
//                                                         sx={{ borderRadius: `${customization.borderRadius}px`,background:"transparent !important" }}
//                                                         selected={selectedIndex === 0}
//                                                         onClick={(event) => handleListItemClick(event, 0, "/profile")}
//                                                     >
//                                                         <ListItemIcon>
//                                                             <FaUserEdit size="1.3rem" />
//                                                         </ListItemIcon>
//                                                         <ListItemText primary={<Typography variant="body2">{t('profile')}</Typography>} />
//                                                     </ListItemButton>
//                                                     :
//                                                     ""
//                                                 }
//                                                 <ListItemButton
//                                                     sx={{ borderRadius: `${customization.borderRadius}px`,background:"transparent !important" }}
//                                                     selected={selectedIndex === 1}
//                                                     onClick={(event) => handleListItemClick(event, 1, "/change_password")}
//                                                 >
//                                                     <ListItemIcon>
//                                                         {/* <IconSettings stroke={1.5} size="1.3rem" /> */}
//                                                         <IconDotsVertical stroke={1.5} size="1.3rem" />
//                                                     </ListItemIcon>
//                                                     <ListItemText primary={<Typography variant="body2">{t('change_password')}</Typography>} />
//                                                 </ListItemButton>
//                                                 <ListItemButton
//                                                     sx={{ borderRadius: `${customization.borderRadius}px`,background:"transparent !important" }}
//                                                     selected={selectedIndex === 4}
//                                                     onClick={handleLogout}
//                                                 >
//                                                     <ListItemIcon>
//                                                         <IconLogout stroke={1.5} size="1.3rem" />
//                                                     </ListItemIcon>
//                                                     <ListItemText primary={<Typography variant="body2" className="text-black">{t('logout')}</Typography>} />
//                                                 </ListItemButton>
//                                             </List>
//                                         </Box>
//                                     </PerfectScrollbar>
//                                 </MainCard>
//                             </ClickAwayListener>
//                         </Paper>
//                     </Transitions>
//                 )}
//             </Popper>
//         </>
//     );
// };

// export default ProfileSection;
