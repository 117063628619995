import { USER_DASHBOARD_DATA,ADMIN_DASHBOARD_DATA } from "../actions";

const initialState = {
    Dashdata: null,
    admindata:null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_DASHBOARD_DATA:
            return {
                ...state,
                Dashdata: action.payload,
            };
            case ADMIN_DASHBOARD_DATA:
                return {
                    ...state,
                    admindata: action.payload,
                };
        default:
            return state;
    }
}