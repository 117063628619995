import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/BCCadmin/index')));
const GeneratePaymentLinks = Loadable(lazy(() => import('views/pages/GenerateLinks/GeneratePaymentLinks')));



const Profile = Loadable(lazy(() => import('views/pages/Profile/Profile')));
const ChangePass = Loadable(lazy(() => import('views/pages/ChangePassword/ChangePass')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'role-dashboard',
            children: [
                {
                    path: '',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'generate',
            children: [
                {
                    path: 'link',
                    element: <GeneratePaymentLinks />
                }
            ]
        },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: 'change_password',
            element: <ChangePass />
        }
    ]
};

export default MainRoutes;
