import { GET_ALL_CLAIMS,GET_RETRY_KYC } from "../actions";

const initialState = {
  all_claims: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CLAIMS:
      return {
        ...state,
        all_claims: action.payload,
      };

    default:
      return state;
  }
}
