import { combineReducers } from 'redux';
import authReducer from './auth/auth.reducer';
// reducer import
import customizationReducer from './customizationReducer';
import loaderReducer from "./loader/loader.reducer";
import HistoryReducer from './TransactionHistory/History.reducer';
import referralReducer from './referral/referral.reducer';
import userReducer from './Users/User.reducer';
import SocialShareReducer from './SocialShare/Share.reducer';
import notificationReducer from './notifications/notification.reducer';
import DashDataReducer from './DashboardData/DashData.reducer';
import languageReducer from './language/language.reducer';
import kycReducer from './KYC/kyc.reducer';
import DepositsReducer from "./Deposits/userdeposits.reducer"
import cardReducer from './MyCard/usercard.reducer'
import bankTransactionReducer from './BankTransaction/transaction.reducer';
import balanceReducer from './Balances/balance.reducer';
import claimReducer from './Claims/claim.reducer';
import analyticsReducer from './Analytics/analytics.reducer';
import perksReducer from './Perks/perk.reducer';
import ordersReducer from './Orders/order.reducer';
import withdrawalAddressReducer from './WhiteList/withdrawalAddressReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    Analytics:analyticsReducer,
    Claims:claimReducer,
    Balance:balanceReducer,
    cards:cardReducer,
    deposits:DepositsReducer,
    kyc:kycReducer,
    customization: customizationReducer,
    auth: authReducer,
    loader: loaderReducer,
    History: HistoryReducer,
    referral: referralReducer,
    users: userReducer,
    share: SocialShareReducer,
    notification: notificationReducer,
    Dashdata: DashDataReducer,
    AllLanguage: languageReducer,
    bankTransaction: bankTransactionReducer,
    perks:perksReducer,
    orders:ordersReducer,
    withdrawalAddress: withdrawalAddressReducer,
});

export default reducer;
