// assets
import {IconDashboard} from "@tabler/icons";
import {MdWallet, MdHistory, MdOutlineLogout} from "react-icons/md";
import {FaRegCreditCard, FaUsersRays, FaUsers, FaLink, FaFileInvoiceDollar, FaMoneyCheckDollar} from "react-icons/fa6";
import {BiTransferAlt} from "react-icons/bi";
import {IoShareSocial} from "react-icons/io5";
import {FaFileInvoice, FaUsersCog} from "react-icons/fa";
import {SiSubstack} from "react-icons/si";
import {AiFillNotification} from "react-icons/ai";
import {TiUserAdd} from "react-icons/ti";
import {SiGoogleanalytics} from "react-icons/si";
import { FaUser } from "react-icons/fa";
import { IoCart } from "react-icons/io5";
import { AiFillProduct } from "react-icons/ai";


// constant
const icons = {
    SiGoogleanalytics,
    IconDashboard,
    MdWallet,
    MdHistory,
    FaRegCreditCard,
    MdOutlineLogout,
    FaUsersRays,
    FaUsers,
    BiTransferAlt,
    IoShareSocial,
    FaFileInvoice,
    SiSubstack,
    FaLink,
    FaUsersCog,
    AiFillNotification,
    FaFileInvoiceDollar,
    TiUserAdd,
    FaMoneyCheckDollar,
    AiFillProduct,
    IoCart,
    FaUser,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: "dashboard",
    title: "Dashboard",
    type: "group",
    children: [
        {
            id: "dashboard",
            title: "Dashboard",
            type: "item",
            url: "/admin-dashboard",
            icon: icons.IconDashboard,
            breadcrumbs: false,
        },
        {
            id: "alluser",
            title: "All Users",
            type: "item",
            url: "utils/users",
            icon: icons.FaUsers,
            breadcrumbs: false,
        },
        {
            id: "alltransactions",
            title: "All Transactions",
            type: "item",
            url: "utils/transactions",
            icon: icons.BiTransferAlt,
            breadcrumbs: false,
        },
        {
            id: "allclaims",
            title: "All Claims",
            type: "item",
            url: "utils/claims",
            icon: icons.FaMoneyCheckDollar,
            breadcrumbs: false,
        },
        {
            id: "socialshares",
            title: "Social Shares",
            type: "item",
            url: "/utils/social-shares",
            icon: icons.IoShareSocial,
            breadcrumbs: false,
        },
        {
            id: "customInvoices",
            title: "Custom Invoices",
            type: "item",
            url: "/utils/custom-invoices",
            icon: icons.FaFileInvoice,
            breadcrumbs: false,
        },
        {
            id: "add_investor",
            title: "Add Investor",
            type: "item",
            url: "/add/investor",
            icon: icons.TiUserAdd,
            breadcrumbs: false,
        },
        // {
        //     id: "settlement",
        //     title: "Settlement",
        //     type: "item",
        //     url: "/create/settlement",
        //     icon: icons.FaFileInvoiceDollar,
        //     breadcrumbs: false,
        // },
        {
            id: "notificationSubscribedUsers",
            title: "Notifications",
            type: "item",
            target: "_blank",
            external: "https://dashboard.onesignal.com",
            url: "https://dashboard.onesignal.com",
            icon: icons.AiFillNotification,
            breadcrumbs: false,
        },
        {
            id: "role",
            title: "Role",
            type: "item",
            url: "/create/role",
            icon: icons.FaUsersCog,
            breadcrumbs: false,
        },
        {
            id: "bccanalytics",
            title: "Analytics",
            type: "item",
            url: "bccoin/analytics",
            icon: icons.SiGoogleanalytics,
            breadcrumbs: false,
        },
        {
            id: "comission_managment",
            title: "Commission Management",
            type: "item",
            url: "comission/managment",
            icon: icons.FaUsersCog,
            breadcrumbs: false,
        },
        {
            id: "perks",
            title: "Perks",
            type: "item",
            url: "/utils/perks",
            icon: icons.AiFillProduct,
            breadcrumbs: false,
        },
        {
            id: "orders",
            title: "Orders",
            type: "item",
            url: "/utils/orders",
            icon: icons.IoCart,
            breadcrumbs: false,
        },
        {
            id: "migrateuser",
            title: "Migrate User",
            type: "item",
            url: "migrate/user",
            icon: icons.FaUser,
            breadcrumbs: false,
        },
        {
            id: "logout",
            title: "Logout",
            type: "item",
            url: "",
            icon: icons.MdOutlineLogout,
            breadcrumbs: false,
        },
    ],
};

export default dashboard;
