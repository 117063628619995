import { GET_CARD_BALANCES } from "../actions";

const initialState = {
  All_balances: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CARD_BALANCES:
      return {
        ...state,
        All_balances: action.payload,
      };
    default:
      return state;
  }
}
