import {GET_PERKS, UPDATE_PERK, CREATE_PERKS} from "../actions";

const initialState = {
    perksData: null,
    perks: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PERKS:
            return {
                ...state,
                perks: action.payload,
            };

        default:
            return state;
    }
}
