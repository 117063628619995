import toast from "react-hot-toast";
import Axios from "../../utils/Axios";

import { 
  SET_REFERRAL_DATA, 
  SET_REFERRAL_USERS,
  SET_REFERRAL_TRANSACTIONS,
  SET_SOCIAL_INFO,
  GET_USER_REFFERALS
} from "../actions";
import {changeflag} from "../loader/loader.action";

export const getReferrals = () => (dispatch) => {

  Axios.get("/users/referrals")
  .then((res) => {

    console.log("getReferrals", res.data.referrals);
      dispatch({
          type: SET_REFERRAL_USERS,
          payload: res.data.referrals
      });
  })
  .catch((error) => {
      console.log("error", error)
  });
};
export const listReffers = () => (dispatch) => {
  dispatch(changeflag(true));
  Axios.get("users/listReffers")
      .then((res) => {
          dispatch({
              type: GET_USER_REFFERALS,
              payload: res.data.reffers,
          });
          dispatch(changeflag(false));
      })
      .catch((error) => {
          dispatch(changeflag(false));
          if (error.response) {
              toast.error(error?.response?.data?.message);
          } else if (error.request) {
              toast.error(error?.request);
          } else {
              toast.error(error?.message);
          }
      });
};
export const getReferralTransactions = () => (dispatch) => {

  Axios.get("/users/referralsTransactions")
  .then((res) => {
      dispatch({
          type: SET_REFERRAL_TRANSACTIONS,
          payload: res.data.transactions
      });
  })
  .catch((error) => {
      console.log("error", error)
  });
};

export const loadReferralData = () => (dispatch) => {

  Axios.get("/token/tokenprice", {
    headers: {
      "Content-Type": "application/json",
    },
  })
  .then((res) => {

      dispatch({
          type: SET_REFERRAL_DATA,
          payload: res.data
      });
  })
  .catch((error) => {
      console.log("error", error)
  });
};

export const sendReferralLink = (data) => async (dispatch) => {

  try {
    const response = await Axios.post("/users/sendReferEmail", data , {
      headers: {
        "Content-Type": "application/json",
      },
    });
    toast.success(response.data.message);
    dispatch(getSocialInfo());


  }catch(error){
    if (error.response) {
        toast.error(error?.response?.data?.message);
    } else if (error.request) {
        toast.error(error?.request);
    } else {
        toast.error(error?.message);
    }
  };
};

export const addSocialInfo = (data) => async (dispatch) => {

  try {
    const response = await Axios.post("/social/add", data);

    console.log("addSocialInfo", response.data)

    dispatch(getSocialInfo());

    toast.success(response.data.message);

  }catch(error){
    if (error.response) {
        toast.error(error?.response?.data?.message);
    } else if (error.request) {
        toast.error(error?.request);
    } else {
        toast.error(error?.message);
    }
  };
};

export const getSocialInfo = () => (dispatch) => {

  Axios.get("/social/list/byuserid")
  .then((res) => {

      dispatch({
          type: SET_SOCIAL_INFO,
          payload: res.data.data
      });
  })
  .catch((error) => {
      console.log("error", error)
  });
};