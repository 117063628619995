import {LOADER_STATUS, AUTHENTICATOR_STATUS, DATA_LOADER} from "../actions";

const initialState = {
    loader_status: false,
    Authenticator_status: false,
    data_loader: true
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADER_STATUS:
            return {
                ...state,
                loader_status: action.payload,
            };
        case AUTHENTICATOR_STATUS:
            return {
                ...state,
                Authenticator_status: action.payload,
            };
        case DATA_LOADER:
            return {
                ...state,
                data_loader: action.payload,
            };

        default:
            return state;
    }
}
