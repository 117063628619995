import {createRoot} from "react-dom/client";

// third party
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import axios from "axios";
axios.defaults.withCredentials = true;

// import * as serviceWorker from 'serviceWorker';

import App from "App";
import {store} from "store";
// style + assets

import "assets/scss/style.scss";
import "./index.css";
import "./utils/i18n.js";
import config from "./config";
import {Toaster} from "react-hot-toast";
import AppLayout from "checklogin/AppLayout";

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <BrowserRouter basename={config?.basename}>
            <Toaster />
            <AppLayout />
        </BrowserRouter>
    </Provider>
);

// serviceWorker.register();
